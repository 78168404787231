import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import {
    InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader,
    ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col
} from "reactstrap";
import Swal from 'sweetalert2';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Autosuggest from 'react-autosuggest';
import { UserContext } from '../context/UserProvider';
import Buscar from '../componentes/Buscar';

const modeloPais = { codigo: "", nombre: "" };

const Pais = (props) => {
    const { user } = useContext(UserContext);
    const [Pais, setPais] = useState(modeloPais);
    const [fila, setFila] = useState(0);
    const [fechaInicio, setFechaInicio] = useState(
        `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}`
    );
    const [verModalPais, setVerModalPais] = useState(false);
    const [fechahoy, setFechahoy] = useState(
        `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`
    );
    // const [novedades, setNovedades] = useState([
    //     {
    //         id: 1, empleado: "Miguel", centrocost: "", concepto: "",
    //         horas: 0, valor: 0, dia: 0, finicial: fechahoy, ffinal: fechahoy
    //     }
    // ]);

    const [fechaIniciohora, setFechaIniciohora] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2) + "T00:00");

    const [dataUser, setDataUser] = useState(null);
    const [titulobuscar, settitulobuscar] = useState("");
    const [namebuscado, setnamebuscado] = useState("");
    const [encabezadobuscar, setencabezadobuscar] = useState([]);
    const [datosbuscados, setdatosbuscados] = useState([]);
    const [verModaltipodocumentos, setverModaltipodocumentos] = useState(false);
    const [novedades, setnovedades] = useState(undefined);
    const [periodo, setperiodo] = useState("1");
    const [Empleados, setEmpleados] = useState([]);
    const [CentrodeCosto, setCentrodeCosto] = useState([]);
    const [Conceptos, setConceptos] = useState([]);
    const toggleModal = () => setVerModalPais(!verModalPais);
    useEffect(() => {
        setDataUser(JSON.parse(user));
        obtenerEmpleados("")
        obtenerCentrosdeCosto("")
        obtenerConceptos("")
    }, [user]);

    const obtenerConceptos = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/ConceptosNominaApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setConceptos(data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const obtenerEmpleados = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/EmpleadosApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setEmpleados(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const obtenerCentrosdeCosto = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/Ctcentrocostoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setCentrodeCosto(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const obtenerNovedades = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            var año = fechaInicio.split("-")[0]
            var mes = fechaInicio.split("-")[1]

            let response = await fetch(props.servidor + '/NovedadesNominaApi/porperiodo/' + año + '/' + mes + '/' + periodo + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setnovedades(data);
                console.log(data)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const guardarnovedades = async () => {
        try {
            let dt = JSON.parse(user);
            var año = fechaInicio.split("-")[0]
            var mes = fechaInicio.split("-")[1]
            let response;
            response = await fetch("" + props.servidor + "/NovedadesNominaApi?llave=" + dt.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(novedades)
            });
            if (response.ok) {
                let data = await response.text();
                Swal.fire(
                    'Guardado Exitosamente!',
                    'Novedades Guardadas Exitosamente',
                    'success'
                );
            }
        } catch (error) {
            console.log(error);
        }
    };
    const agregarnovedad = async (coincidencia) => {
        try {
            var _novedades = JSON.parse(JSON.stringify(novedades))
            _novedades.push({
                item: 0, empleado: "", centrodecosto: "", concepto: "",
                horas: 0, valor: 0, dia: 0, fechainicial: fechaIniciohora, fechafinal: fechaIniciohora,
                horafecha: "", ano: fechaInicio.split("-")[0], mes: fechaInicio.split("-")[1], periodo: periodo, cerrado: "no"

            })
            setnovedades(_novedades)
        } catch (error) {
            console.log(error);
        }
    };
    const cerrartodo = () => {
        settitulobuscar("")
        setencabezadobuscar([])
        setverModaltipodocumentos(false)
        setdatosbuscados([])
        setnamebuscado("")
    };

    const handlerchangenovedad = async (name, valor) => {
        try {
            var _novedades = JSON.parse(JSON.stringify(novedades))
            var _enviodatos = _novedades.map((dato, index) => {
                if (index == fila) {
                    var dat = {
                        ...dato,
                        [name]: valor
                    }
                    return dat
                } else {
                    return dato
                }
            })
            setnovedades(_enviodatos)
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Card>
            <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                Novedades
            </CardHeader>
            <CardBody>
                <div className="col-sm-6">
                    <InputGroup className="mb-3 input-group-sm">
                        <Input
                            bsSize="sm"
                            type="month"
                            className="form-control"
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}
                        />
                        <Input type="select" value={periodo} style={{ fontSize: '13px' }}
                            onChange={(e) => {
                                setperiodo(e.target.value)

                            }}
                        >
                            <option value="-">-</option>
                            <option value="1">Primera Quincena</option>
                            <option value="2">Segunda Quincena</option>
                            <option value="1">Mensual</option>
                        </Input>
                        <Button color="success" size="sm" className="ml-2" onClick={() => { obtenerNovedades("") }}>
                            Buscar
                        </Button>
                        <Button style={{ backgroundColor: '#188BAD' }} size="sm" className="ml-2" onClick={guardarnovedades}>
                            Guardar
                        </Button>
                        <Col sm={1}>
                            <Button
                                style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }}
                                block
                                onClick={() => props.setcrearpais(false)}
                            >
                                <i className="fa fa-times"></i>
                            </Button>
                        </Col>
                    </InputGroup>
                </div>
                <hr />
                <Row>
                    <Col sm={12}>
                        <Card style={{ borderColor: '#188BAD' }}>
                            <CardBody>
                                <div className="table-responsive" style={{ height: '300px', overflow: 'auto' }}>
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <th>Empleado</th>
                                                <th>C. Costo</th>
                                                <th>Concepto</th>
                                                <th>Se. Social</th>
                                                <th>Fecha Inicial</th>
                                                <th>Fecha Final</th>
                                                <th>Día</th>
                                                <th>Horas</th>
                                                <th>Valor</th>
                                                <th>Tipo</th>
                                                <th>Agregar</th>
                                                <th>Prev</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {novedades ? novedades.length < 1 ? (
                                                <tr>
                                                    <td colSpan="10" className="text-center">
                                                        <button className="btn btn-primary ms-3" onClick={agregarnovedad}>
                                                            Agregar Novedades
                                                        </button>
                                                    </td>
                                                </tr>
                                            ) : (
                                                novedades && novedades.map((item, index) => (
                                                    <tr key={index} style={{ zoom: '75%' }} onFocus={() => {
                                                        setFila(index)
                                                        console.log(index)
                                                        console.log(fila)

                                                    }}>
                                                        <td><Input
                                                            onFocus={() => {
                                                                settitulobuscar("Empleados")
                                                                setencabezadobuscar(["codigo", "nombre", "", ""])
                                                                setnamebuscado("empleado")
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key == "Enter") {

                                                                    setverModaltipodocumentos(true)
                                                                    setdatosbuscados(Empleados)
                                                                }
                                                            }}
                                                            name='empleado' value={item.empleado} /></td>
                                                        <td><Input onFocus={() => {
                                                            settitulobuscar("Centro de costos")
                                                            setencabezadobuscar(["codigo", "nombre", "", ""])
                                                            setnamebuscado("centrodecosto")
                                                        }}
                                                            onKeyDown={(e) => {
                                                                if (e.key == "Enter") {

                                                                    setverModaltipodocumentos(true)
                                                                    setdatosbuscados(CentrodeCosto)
                                                                }
                                                            }} name='centrodecosto' value={item.centrodecosto} /></td>
                                                        <td><Input onFocus={() => {
                                                            settitulobuscar("Concepto")
                                                            setencabezadobuscar(["codigo", "nombre", "", ""])
                                                            setnamebuscado("concepto")
                                                        }}
                                                            onKeyDown={(e) => {
                                                                if (e.key == "Enter") {

                                                                    setverModaltipodocumentos(true)
                                                                    setdatosbuscados(Conceptos)
                                                                }
                                                            }} name='concepto' value={item.concepto} /></td>
                                                        <td><Input value={Conceptos.find(p => p.codigo == item.concepto)?.opcion4 || ""} /></td>
                                                        <td><Input disabled={!(
                                                            Conceptos.find(p => p.codigo == item.concepto)?.formaliquidacion == "dia" ||
                                                            Conceptos.find(p => p.codigo == item.concepto)?.formaliquidacion == "horas"
                                                        )} type="datetime-local" onChange={(e) => { 
                                                            handlerchangenovedad(e.target.name, e.target.value) 
                                                            console.log(e.target.value)
                                                            }} name='finicial' value={item.finicial} /></td>
                                                        <td><Input disabled={!(
                                                            Conceptos.find(p => p.codigo == item.concepto)?.formaliquidacion == "dia" ||
                                                            Conceptos.find(p => p.codigo == item.concepto)?.formaliquidacion == "horas"
                                                        )} type="datetime-local" name='ffinal' onChange={(e) => { handlerchangenovedad(e.target.name, e.target.value) }} value={item.ffinal} /></td>
                                                        <td><Input disabled={!(
                                                            Conceptos.find(p => p.codigo == item.concepto)?.formaliquidacion == "dia"
                                                        )} name='dia' onChange={(e) => { handlerchangenovedad(e.target.name, e.target.value) }} value={item.dia} /></td>
                                                        <td><Input disabled={!(
                                                            Conceptos.find(p => p.codigo == item.concepto)?.formaliquidacion == "horas"
                                                        )} name='horas' onChange={(e) => { handlerchangenovedad(e.target.name, e.target.value) }} value={item.horas} /></td>
                                                        <td><Input disabled={!(
                                                            Conceptos.find(p => p.codigo == item.concepto)?.formaliquidacion == "valor"
                                                        )} name='valor' onChange={(e) => { handlerchangenovedad(e.target.name, e.target.value) }} value={item.valor} /></td>
                                                        <td><Input disabled={true} value={Conceptos.find(p => p.codigo == item.concepto)?.tipo || ""} /></td>

                                                        <td>
                                                            <Button style={{ backgroundColor: '#198BBD' }} size="sm" onClick={agregarnovedad}>
                                                                Agregar
                                                            </Button>
                                                        </td>
                                                        <td>
                                                            <Button style={{ backgroundColor: '#188BAD' }} size="sm" onClick={toggleModal}>
                                                                Prev
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="10">Nada Seleccionado</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Buscar tituloencabezado={titulobuscar} filtrar={(coincidencia) => {
                    // filtrartipodocumentos(coincidencia)
                }}
                    busqueda={datosbuscados} encabezado={encabezadobuscar}
                    codigo={encabezadobuscar[0]} nombre={encabezadobuscar[1]} dato={encabezadobuscar[2]} dato2={encabezadobuscar[3]}
                    verModal={verModaltipodocumentos} cerrarModal={() => {
                        cerrartodo()

                    }} seleccionar={(seleccionado) => {
                        var _novedades = JSON.parse(JSON.stringify(novedades))
                        var _enviodatos = _novedades.map((dato, index) => {
                            if (index == fila) {
                                var dat = {
                                    ...dato,
                                    [namebuscado]: seleccionado.codigo
                                }
                                return dat
                            } else {
                                return dato
                            }
                        })
                        setnovedades(_enviodatos)
                        cerrartodo()

                    }} />
            </CardBody>
        </Card>
    );
};

export default Pais;
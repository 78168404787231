import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, CardHeader, Button, FormGroup, Label, Input, Row, Col } from "reactstrap";
import Swal from 'sweetalert2';
import { UserContext } from '../context/UserProvider';

const FacturaBloque = (props) => {
    const { user } = useContext(UserContext);
    const [usuario, setUsuario] = useState({});
    const [fileD, setFileD] = useState([]);
    const [fechahoy, setFechahoy] = useState(new Date().toISOString().split('T')[0]);
    const [mesSeleccionado, setMesSeleccionado] = useState('');
    const [centrosCostos, setCentrosCostos] = useState([]);
    const [productos, setProductos] = useState([]);
    const [conceptos, setConceptos] = useState([]);
    const [centroSeleccionado, setCentroSeleccionado] = useState('');
    const [productoSeleccionado, setProductoSeleccionado] = useState('');
    const [conceptoSeleccionado, setConceptoSeleccionado] = useState('');

    useEffect(() => {
        setUsuario(JSON.parse(user));
        obtenerCentrosCostos('');
        obtenerProductos('');
        obtenerConceptos('');
    }, []);

    const obtenerCentrosCostos = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(`${props.servidor}/Ctcentrocostoapi/${coincidencia}?llave=${dt.usuario.llaveempresa}`);
            if (response.ok) {
                let data = await response.json();
                setCentrosCostos(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const obtenerProductos = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(`${props.servidor}/Inarticulosapi/${coincidencia}?llave=${dt.usuario.llaveempresa}`);
            if (response.ok) {
                let data = await response.json();
                setProductos(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const obtenerConceptos = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(`${props.servidor}/Fatipodocapi/${coincidencia}?llave=${dt.usuario.llaveempresa}`);
            if (response.ok) {
                let data = await response.json();
                setConceptos(data.filter(p=>p.tipodoc=='factura'));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const guardarFactura = async () => {
        try {
            let response = await fetch(`${props.servidor}/Movimientoapi/bloquecolegio/${fechahoy}/${mesSeleccionado}/${centroSeleccionado}/${productoSeleccionado}
                /${conceptoSeleccionado}?llave=${usuario.usuario.llaveempresa}`);
            
            if (response.ok) {
                Swal.fire('Éxito', 'Facturas generadas', 'success');
            } else {
                let errorMsg = await response.json();
                Swal.fire('Error', errorMsg.mensaje, 'error');
            }
        } catch (error) {
            Swal.fire('Error', error.message, 'error');
        }
    };

    return (
        <Card>
            <CardHeader style={{ backgroundColor: '#188BAD', color: 'white' }}>
                Factura en Bloque
            </CardHeader>
            <CardBody>
                <Row>
                    <Col sm={3}>
                        <FormGroup>
                            <Label>Fecha</Label>
                            <Input type="date" value={fechahoy} onChange={(e) => setFechahoy(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <Label>Mes y Año</Label>
                            <Input type="month" value={mesSeleccionado} onChange={(e) => setMesSeleccionado(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <Label>Centro de Costos</Label>
                            <Input type="select" value={centroSeleccionado} onChange={(e) => setCentroSeleccionado(e.target.value)}>
                                <option value="">Seleccione...</option>
                                {centrosCostos.map((centro) => (
                                    <option key={centro.codigo} value={centro.codigo}>{centro.nombre}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <Label>Producto</Label>
                            <Input type="select" value={productoSeleccionado} onChange={(e) => setProductoSeleccionado(e.target.value)}>
                                <option value="">Seleccione...</option>
                                {productos.map((producto) => (
                                    <option key={producto.codigo} value={producto.codigo}>{producto.nombre}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label>Concepto</Label>
                            <Input type="select" value={conceptoSeleccionado} onChange={(e) => setConceptoSeleccionado(e.target.value)}>
                                <option value="">Seleccione...</option>
                                {conceptos.map((concepto) => (
                                    <option key={concepto.codigo} value={concepto.codigo}>{concepto.descripcion}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Button color="primary" onClick={guardarFactura}>Crear Facturas</Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default FacturaBloque;

import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { Table, Row, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Col } from "reactstrap"
import Swal from 'sweetalert2'
import { UserContext } from "../context/UserProvider";
import { Autocomplete, TextField } from '@mui/material';

const modeloGrupo = {
    nitempresa: "",
    codigogrupo: "",
    nombreempresa: "",
    nombregrupo: "",
    modulos: [{
        id: 0,
        codigomodulo: "",
        nombremodulo: "",
        editar: true,
        buscar: true,
        imprimir: true,
        eliminar: true,
        adicionar: true,
        electronico: true
    }]
}

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidorempresa: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}

const constpermisoss = [
    {
        codigo: 'anular',
        nombre: 'Puedo Anular'
    },
    {
        codigo: 'cambiarfecha',
        nombre: 'Cambiar Fecha'
    },
    {
        codigo: 'facturarennegativo',
        nombre: 'Facturar en Negativo'
    },
    {
        codigo: 'nodejarcambiaeprecio',
        nombre: 'Dejar Cambiar Precio'
    },
    {
        codigo: 'nodejarcambiaepreciohaciaabajo',
        nombre: 'No dejar Cambiar Precio Hacia Abajo'
    },
    {
        codigo: 'ocultarcostoreferencia',
        nombre: 'Ocultar Costo Referencia'
    },
    {
        codigo: 'ocultarultimocostoreferencia',
        nombre: 'Ocultar Ultimo Costo Referencia'
    },
    {
        codigo: 'primeroterceroenprecio',
        nombre: 'Primero el Tercero en Precio'
    },
    {
        codigo: 'tipodocumento',
        nombre: 'Tipo Documento'
    },
    {
        codigo: 'tipodocumentounico',
        nombre: 'Tipo Documento Unico'

    },
    {
        codigo: 'tipodocumentoeditable',
        nombre: 'Tipo Documento Editable'

    },
    {
        codigo: 'cajafija',
        nombre: 'Caja Fija'

    },
    {
        codigo: 'facturarconuvt',
        nombre: 'Facturar Con Mas de 5 UVT'
    },
    {
        codigo: 'dejardesactivarreferencias',
        nombre: 'Dejar Desactivar Referencias'
    },
    {
        codigo: 'nodejarimprimirsinguardar',
        nombre: 'No Dejar Imprimir sin guardar'
    },
    {
        codigo: 'nodejarcambiarrentabilidad',
        nombre: 'No Dejar Cambiar Rentabilidad'
    },
    {
        codigo: 'cantidadminima',
        nombre: 'Cantidad Minima'
    },
    {
        codigo: 'nocambiarpreciocambiarlistadeprecio',
        nombre: 'No cambiar precio y si cambiar lista'
    },
    {
        codigo: 'desactivarexistenciaeninventariofisico',
        nombre: 'Desactivar empresa en inventario fisico'
    },
    {
        codigo: 'nodejareliminarproductos',
        nombre: 'No dejar eliminar productos'
    },
    {
        codigo: 'cierrecaja',
        nombre: 'Cierre de Caja'
    },
    {
        codigo: 'dejarimprimir2veces',
        nombre: 'Dejar Imprimir 2 Veces'
    },
    {
        codigo: 'editartipodocumentos',
        nombre: 'Dejar Editar Tipo Documentos'
    },
    {
        codigo: 'cupocredito',
        nombre: 'Cupo Credito'
    },
    {
        codigo: 'facturasamasdendias',
        nombre: 'Facturas a mas de N Dias'
    },
    {
        codigo: 'nodejarcambiariva',
        nombre: 'No dejar cambiar Iva'
    },
    {
        codigo: 'manejaturno',
        nombre: 'Maneja Turno'
    },
    {
        codigo: 'solomanejainformeinventario',
        nombre: 'Solo Maneja Informe Inventario'
    },
    {
        codigo: 'vertiposdedocumentoenbusqueda',
        nombre: 'Ver Tipos de Documento en Busqueda'
    },
    {
        codigo: 'ocultarcostoenmovimientos',
        nombre: 'Ocultar costos en Movimientos'
    },
    {
        codigo: 'nodejarvercierredecaja',
        nombre: 'No dejar ver Cierre de Caja'
    }

]
const Grupo = (props) => {

    const [grupo, setGrupo] = useState(modeloGrupo);
    const [pendiente, setPendiente] = useState(true);
    const [grupos, setGrupos] = useState([]);
    const [verModal, setVerModal] = useState(false);
    const [modulos, setmodulos] = useState([]);
    const [render, setrender] = useState([]);
    const [empresas, setempresas] = useState([]);
    const [todaslasempresas, settodaslasempresas] = useState(false);
    const [editar, seteditar] = useState(false);
    const [dataUser, setDataUser] = useState(modelo)
    const { user } = useContext(UserContext)
    const [fila, setfila] = useState(0);
    const [tipodocumentos, settipodocumentos] = useState([]);
    const [tipos, settipos] = useState([]);
    const [roles, setRoles] = useState([]);

    const handleChange = (e) => {
        let value = e.target.value
        console.log(grupo)


        if (e.target.name == 'nitempresa') {
            setGrupo({
                ...grupo,
                [e.target.name]: value,
                ['nombreempresa']: e.target.options[e.target.selectedIndex].text
            })
        } else {
            setGrupo({
                ...grupo,
                [e.target.name]: value

            })
        }

        console.log(grupo)
    }

    const obtenerEmpresas = async (coincidencia) => {
        let response = await fetch(props.servidorempresa + '/Empresaapi/nombres/' + coincidencia);
        let dt = JSON.parse(user)

        if (response.ok) {
            let data = await response.json()
            if (props.usuariounico) {
                var data2 = data.map((dat) => {
                    if (dat.nit == dt.empresa.empresa.nit) {
                        return dat
                    }

                })
                data2 = data2.filter(p => p != undefined)
                setempresas(data2)
            } else {
                setempresas(data)
            }
        }
    }
    const obtenerModulos = async (coincidencia) => {
        let response = await fetch(props.servidorempresa + '/Moduloapi/' + coincidencia);
        var modulosconpermisos = [];
        var modulosconpermisosdefecto = [];
        if (response.ok) {
            let data = await response.json()
            console.log(data)
            var grupo = {
                nitempresa: "",
                codigogrupo: "",
                nombreempresa: "",
                nombregrupo: "",
                modulos: []
            };
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                modulosconpermisos.push({
                    codigomodulo: element.codigo,
                    nombremodulo: element.nombre,
                    editar: true,
                    buscar: true,
                    imprimir: true,
                    eliminar: true,
                    adicionar: true,
                    electronico: true,

                })
                modulosconpermisosdefecto.push({
                    codigomodulo: element.codigo,
                    nombremodulo: element.nombre,
                    editar: true,
                    buscar: true,
                    imprimir: true,
                    eliminar: true,
                    adicionar: true,
                    electronico: true,

                })
            }
            grupo.modulos = modulosconpermisos
            setGrupo(grupo)
            setmodulos(modulosconpermisosdefecto)
        }
    }
    const obtenerGrupos = async (coincidencia) => {
        let response = await fetch(props.servidorempresa + '/Grupoapi/' + coincidencia);
        let dt = JSON.parse(user)

        if (response.ok) {
            let data = await response.json()
            if (props.usuariounico) {
                var data2 = data.map((dat) => {
                    if (dat.nitempresa == dt.empresa.empresa.nit) {
                        return dat
                    }

                })
                data2 = data2.filter(p => p != undefined)
                setGrupos(data2)
                setPendiente(false)
            } else {
                setGrupos(data)
                setPendiente(false)
            }

        }
    }

    const handleChangemodulos = (e, index) => {

        console.log(e.target.getAttribute('value'))

        var value;
        value = e.target.getAttribute('value');
        var acc = e.target.getAttribute('acceso');

        var moduloscambio = { ...grupo }.modulos.map((modulo, index2) => {
            if (index2 == index) {
                var numm = Number(acc + "")
                var accesocom = modulo.acceso
                if (value != 'false') {
                    var uno = "1"

                    if (modulo.acceso.length >= numm) {
                        uno = modulo.acceso[numm]
                        var caracteres = accesocom.split('');
                        caracteres[numm] = "1";
                        accesocom = caracteres.join('');
                        var mod = {
                            ...modulo,
                            [e.target.getAttribute('name')]: accesocom
                        }
                        return mod
                    } else {
                        return modulo
                    }

                } else {
                    var uno = "1"
                    if (modulo.acceso.length >= numm) {
                        uno = modulo.acceso[numm]
                        var caracteres = accesocom.split('');
                        caracteres[numm] = "2";
                        accesocom = caracteres.join('');
                        var mod = {
                            ...modulo,
                            [e.target.getAttribute('name')]: accesocom
                        }
                        return mod
                    } else {
                        return modulo
                    }


                }

            } else {
                return modulo
            }
        });


        var empresa = []
        setrender(empresa)
        setGrupo({
            ...grupo,
            modulos: moduloscambio
        })

        console.log(moduloscambio)
    }

    useEffect(() => {
        obtenerGrupos('');
        obtenerModulos('');
        obtenerEmpresas('');
        buscartodastipodocumentos('')
        obtenerTipoPermiso('')
        seteditar(false);
    }, [])


    const columns = [
        {
            name: 'Grupo',
            selector: row => row.codigogrupo,
            sortable: true,
        },
        {
            name: 'Nombre Grupo',
            selector: row => row.nombregrupo,
            sortable: true,
        },
        {
            name: 'Empresa',
            selector: row => row.nitempresa,
            sortable: true,
        },
        {
            name: 'Nombre Empresa',
            selector: row => row.nombreempresa,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                    <Button color="danger" size="sm"
                        onClick={() => eliminarGrupo(row.idGrupo)}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </Button>
                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    const abrirEditarModal = async (data) => {
        delete data.modulos;
        var permisoss = await obtenerModeloContablesDetalado(data.codigogrupo)
        data.modulos = permisoss
        setGrupo(data);

        setVerModal(!verModal);
        seteditar(true)
    }

    const obtenerTipoPermiso = async (codigo) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/PermisosApi/tipo?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()

                settipos(constpermisoss)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const obtenerModeloContablesDetalado = async (codigo) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/PermisosApi/unico/' + codigo + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const cerrarModal = () => {
        modeloGrupo.modulos = modulos
        setGrupo(modeloGrupo)
        setVerModal(!verModal);
        seteditar(false)
        // obtenerModulos('');
        settodaslasempresas(false)
    }

    const guardarCambios = async () => {

        if ((grupo.nitempresa != "" || todaslasempresas) && grupo.codigogrupo != "" && grupo.nombregrupo != "") {

            let response;
            var grupoenvio = { ...grupo }.modulos
            let dt = JSON.parse(user)

            grupoenvio = grupoenvio.map((data) => {
                var dat = data
                dat.grupo = grupo.codigogrupo
                return dat
            })

            try {
                response = await fetch(props.servidor + "/PermisosApi" + '?llave=' + dt.usuario.llaveempresa + '', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(grupoenvio)
                })
                if (response.ok) {
                    await obtenerGrupos('');
                    modeloGrupo.modulos = modulos
                    setGrupo(modeloGrupo)
                    setVerModal(!verModal);
                    settodaslasempresas(false)

                    Swal.fire(
                        'Guardado Exitosamente!',
                        'El Grupo fue guardado exitosamente',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Error al Guardar!',
                        'Sucedio un error al guardar el Grupo',
                        'error'
                    )
                }
            } catch (error) {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el Grupo',
                    'error'
                )
            }

        } else {
            Swal.fire(
                'Datos Incompletos!',
                'Tiene que completar todos los Datos',
                'error'
            )


        }
    }


    const eliminarGrupo = async (id) => {

        Swal.fire({
            title: 'Esta seguro?',
            text: "Desesa eliminar esta grupo",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {

                const response = fetch("api/grupo/Eliminar/" + id, { method: "DELETE" })
                    .then(response => {
                        if (response.ok) {

                            obtenerGrupos();

                            Swal.fire(
                                'Eliminado!',
                                'La grupo fue eliminada.',
                                'success'
                            )
                        }
                    })

            }
        })
    }
    const agregarcondatosinarticuloscompuesto = async (__Inarticulos) => {

        var _Inarticulos = __Inarticulos
        var _inarticuloslistaprecios = _Inarticulos.modulos
        if (true) {
            var _inarticuloslistapreciosfinales = _inarticuloslistaprecios.concat({
                grupo: "",
                tipo: "",
                tipodoc: "",
                acceso: "11111",
            })
            _Inarticulos.modulos = _inarticuloslistapreciosfinales
            setGrupo(_Inarticulos)
            setRoles([])
        }

    }

    const agregarinarticuloscompuesto = async (e, index) => {
        var _Inarticulos = { ...grupo }
        if (_Inarticulos.codigo != '') {
            var _inarticuloslistaprecios = { ...grupo }.modulos
            var _inarticuloslistapreciosfinales = _inarticuloslistaprecios.concat({
                grupo: "",
                tipo: "",
                tipodoc: "",
                acceso: "",
            })
            _Inarticulos.modulos = _inarticuloslistapreciosfinales
            setGrupo(_Inarticulos)
        } else {
            Swal.fire(
                'Opps',
                'No ha digitado un codigo',
                'error'
            )
        }
    }
    const handleChange5 = (e, values) => {

        var _Inarticulos = { ...grupo }
        var subtabla = "modulos"
        var _datos = { ...grupo }[subtabla]
        var coddd = ""
        if (values) {
            coddd = values.codigo
        }
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {

                var dat = {
                    ...dato,
                    ['tipo']: coddd
                }

                return dat
            } else {
                return dato
            }
        })
        _Inarticulos[subtabla] = _enviodatos
        // setInarticulos(_Inarticulos)

        setGrupo(_Inarticulos)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')

    }
    const handleChange6 = (e, values) => {

        var _Inarticulos = { ...grupo }
        var subtabla = "modulos"
        var _datos = { ...grupo }[subtabla]
        var coddd = ""
        if (values) {
            coddd = values.codigo
        }
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {

                var dat = {
                    ...dato,
                    ['tipodoc']: coddd
                }

                return dat
            } else {
                return dato
            }
        })
        _Inarticulos[subtabla] = _enviodatos
        // setInarticulos(_Inarticulos)

        setGrupo(_Inarticulos)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')

    }
    const buscartodastipodocumentos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Fatipodocapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {

                    settipodocumentos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    settipodocumentos([])
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangegrillainarticuloscompuesto = async (e) => {
        var _Inarticulos = { ...grupo }
        var subtabla = "modulos"
        var _datos = { ...grupo }[subtabla]
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                if (e.target.type == "number") {
                    var dat = {
                        ...dato,
                        [e.target.name]: parseFloat(e.target.value)
                    }
                }
                else {
                    var dat = {
                        ...dato,
                        [e.target.name]: e.target.value
                    }
                }
                return dat
            } else {
                return dato
            }
        })
        _Inarticulos[subtabla] = _enviodatos
        // setInarticulos(_Inarticulos)

        setGrupo(_Inarticulos)


    }
    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Grupos
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6" >
                        <div className="input-group input-group-sm mb-3" >
                            <div className="input-group-prepend" >

                                <input type="text" id="buscardataGrupos" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerGrupos(document.getElementById('buscardataGrupos').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => setVerModal(!verModal)}>Nuevo</Button>

                        </div>
                    </div>
                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={grupos}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>

            <Modal size="lg" style={{ maxWidth: '1600px', width: '85%' }} isOpen={verModal}>
                <ModalHeader>
                    Grupo
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Codigo</Label>
                                <Input disabled={editar} bsSize="sm" name="codigogrupo" onChange={handleChange} value={grupo.codigogrupo} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Nombre</Label>
                                <Input bsSize="sm" name="nombregrupo" onChange={handleChange} value={grupo.nombregrupo} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Empresa</Label>
                                <Input disabled={todaslasempresas} bsSize="sm" type={"select"} name="nitempresa" config={"true"} onChange={handleChange} value={grupo.nitempresa} >
                                    <option value={0}>Seleccionar</option>
                                    {
                                        empresas.map((item) => (<option value={item.nit}>{item.nombre}</option>))
                                    }
                                </Input>

                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Seleccionar todas las Empresas</Label>
                                <Input disabled={editar} bsSize="sm" type={"select"} name="nitempresa" config={"true"} onChange={(e) => { settodaslasempresas(e.target.value == 0 ? false : true) }} >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>

                                </Input>

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>

                            <Table striped size="sm" className="table-responsive" style={{ height: '300px' }}>
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th>Tipo Doc</th>
                                        <th>Clave</th>
                                        <th>Acceso</th>
                                        <th>Modificar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (grupo.modulos.length < 1) ? (
                                            <tr>
                                                <td colSpan="5">{<a href="#" onClick={(e) => { agregarinarticuloscompuesto(e) }} className={"badge badge-info p-2"}>{"Agregar"}</a>}</td>
                                            </tr>
                                        ) :
                                            (
                                                grupo.modulos.map((item, index) => (
                                                    <tr key={(index + 1)}>
                                                        <td >
                                                            <Col sm={6}>
                                                                <Autocomplete
                                                                    onChange={handleChange5}
                                                                    name="referenciacompuesto"
                                                                    disablePortal
                                                                    onFocus={() => { setfila(index) }}
                                                                    options={tipos}
                                                                    getOptionLabel={(clasi) => clasi.nombre}
                                                                    sx={{ width: 300 }}
                                                                    value={tipos.filter(p => p.codigo == grupo.modulos[index].tipo)[0]}
                                                                    renderInput={(params) => <TextField {...params} label="" />}
                                                                />
                                                            </Col>
                                                        </td>
                                                        <td >
                                                            {grupo.modulos[index].tipo != "cajafija" ?
                                                                <Col sm={6}>
                                                                    <Autocomplete
                                                                        onChange={handleChange6}
                                                                        name="referenciacompuesto"
                                                                        disablePortal
                                                                        onFocus={() => { setfila(index) }}
                                                                        options={tipodocumentos}
                                                                        getOptionLabel={(clasi) => clasi.codigo + " - " + clasi.descripcion}
                                                                        sx={{ width: 300 }}
                                                                        value={tipodocumentos.filter(p => p.codigo == grupo.modulos[index].tipodoc)[0]}
                                                                        renderInput={(params) => <TextField {...params} label="" />}
                                                                    />
                                                                </Col> :
                                                                <Col sm={6}>
                                                                    <Autocomplete
                                                                        onChange={handleChange6}
                                                                        name="referenciacompuesto"
                                                                        disablePortal
                                                                        onFocus={() => { setfila(index) }}
                                                                        options={props.cajasbusqueda}
                                                                        getOptionLabel={(clasi) => clasi.nombre}
                                                                        sx={{ width: 300 }}
                                                                        value={props.cajasbusqueda.filter(p => p.codigo == grupo.modulos[index].tipodoc)[0]}
                                                                        renderInput={(params) => <TextField {...params} label="" />}
                                                                    />
                                                                </Col>

                                                            }

                                                        </td>
                                                        <td >
                                                            <Col sm={6}>
                                                                <Input style={{ width: '100px' }} value={item.clave} onChange={(e) => {
                                                                    var _Inarticulos = { ...grupo }
                                                                    var subtabla = "modulos"
                                                                    var _datos = { ...grupo }[subtabla]
                                                                    let _enviodatos = _datos.map((dato, index2) => {
                                                                        if (index2 == index) {

                                                                            var dat = {
                                                                                ...dato,
                                                                                ['clave']: e.target.value
                                                                            }

                                                                            return dat
                                                                        } else {
                                                                            return dato
                                                                        }
                                                                    })
                                                                    _Inarticulos[subtabla] = _enviodatos
                                                                    setGrupo(_Inarticulos)

                                                                }} />
                                                            </Col>
                                                        </td>
                                                        <td>{<a href="#" value={item.acceso ? item.acceso[0] == "2" ? true : false : false} acceso={0} onClick={(e) => { handleChangemodulos(e, index) }} name='acceso' className={item.acceso ? item.acceso[0] == "2" ? "badge badge-info p-2" : "badge badge-danger p-2" : "badge badge-danger p-2"}>{item.acceso ? item.acceso[0] == "2" ? "Activo" : "No Activo" : "No Activo"}</a>}</td>
                                                        <td>{<a href="#" value={item.acceso ? item.acceso[1] == "2" ? true : false : false} acceso={1} onClick={(e) => { handleChangemodulos(e, index) }} name='acceso' className={item.acceso ? item.acceso[1] == "2" ? "badge badge-info p-2" : "badge badge-danger p-2" : "badge badge-danger p-2"}>{item.acceso ? item.acceso[1] == "2" ? "Activo" : "No Activo" : "No Activo"}</a>}</td>


                                                        <tr>
                                                            <td colSpan="1">{<a href="#" onClick={(e) => { agregarcondatosinarticuloscompuesto(grupo) }} className={"badge badge-info p-2"}>{"Agregar Compuesto"}</a>}</td>
                                                        </tr>
                                                    </tr>
                                                ))
                                            )


                                    }
                                </tbody>
                            </Table>
                        </Col>

                    </Row>
                    {/* grilla */}
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>



    )
}

export default Grupo;
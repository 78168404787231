import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from '../../../context/UserProvider';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'
import Filtro from '../../../componentes/filtro';
const tabespacio = '\u00A0';
const modeloInicio = [{
    codigo: "",
    nombre: "",
    unimed: "",
    listadeprecio: "",
    precio: ""

}]

const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));
    const [terceroinicioseleccionado, setterceroinicioseleccionado] = useState("-");
    const [tercerofinseleccionado, settercerofinseleccionado] = useState("-");
    const [pendiente, setPendiente] = useState(false);
    const [conivacosto, setconivacosto] = useState("no");
    const [ventas, setVentas] = useState(modeloInicio)
    const [ventasporvendedor, setVentasporvendedor] = useState([])
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));
    const [fm4, setfm4] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm2, setfm2] = useState(new FormatMoney({ symbol: '%', decimals: 2 }));
    const [filtros, setfiltros] = useState(false);
    const [mayora, setmayora] = useState(false);
    const [tipodocumentoinicioseleccionado, settipodocumentoinicioseleccionado] = useState("-");
    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [referenciafinseleccionado, setreferenciafinseleccionado] = useState("-");
    const [referenciainicioseleccionado, setreferenciainicioseleccionado] = useState("-");
    const [clasificacionarticulo1, setclasificacionarticulo1] = useState("-");
    const [coincidencianombre, setcoincidencianombre] = useState("-");
    const [vendedorinicioseleccionado, setvendedorinicioseleccionado] = useState("-");
    const [vendedorfinseleccionado, setvendedorfinseleccionado] = useState("-");
    const [sucursal, setsucursal] = useState("-");
    const [vendedor, setvendedor] = useState(false);
    const buscar = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        const api = fetch(props.servidor + "/Inarticulosapi/reportecostomercanciavendida/" + conivacosto + "/" + fechaInicio + "/" + fechaFin + "/" + tipodocumentoinicioseleccionado + "/" + tipodocumentofinseleccionado + "/" + referenciafinseleccionado + "/" + referenciainicioseleccionado + "/" + coincidencianombre + "/" + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + vendedorinicioseleccionado + "/" + vendedorfinseleccionado + '/' + sucursal + "?llave=" + dt.usuario.llaveempresa)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                setcoincidencianombre("-")
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setVentas(data);
                var ventasPorVendedor = {};

                data.forEach(venta => {
                    if (!ventasPorVendedor.hasOwnProperty(venta.vendedor)) {
                        // Si el vendedor no está en el objeto, creamos una entrada para él
                        ventasPorVendedor[venta.vendedor] = {
                            vendedor: venta.nombreven,
                            costo: venta.costo, // Suponiendo que cada venta tiene un campo 'total'
                            valorventa: venta.valorventa, // Suponiendo que cada venta tiene un campo 'total'
                            utilidad: venta.utilidad, // Suponiendo que cada venta tiene un campo 'total'
                        };
                    } else {
                        // Si el vendedor ya está en el objeto, sumamos el total de ventas
                        ventasPorVendedor[venta.vendedor].costo += venta.costo;
                        ventasPorVendedor[venta.vendedor].valorventa += venta.valorventa;
                        ventasPorVendedor[venta.vendedor].utilidad += venta.utilidad;
                    }
                });
                try {
                    var arrayventasvendedor = Object.values(ventasPorVendedor)

                    setVentasporvendedor(arrayventasvendedor)
                    console.log(ventasPorVendedor)
                } catch (error) {
                    console.log(error)
                }
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var _ventas = [];

        // Procesamos las ventas y calculamos totales
        let totalCantidad = 0;
        let totalCosto = 0;
        let totalValorVenta = 0;
        let totalUtilidad = 0;
        let totalUtilCosto = 0;

        ventas.forEach(element => {
            var venn = {
                Referencia: element.codigo,
                Descripcion: element.nombre,
                Cantidad: element.cantidad,
                Costo: element.costo,
                ValorVenta: element.valorventa,
                Utilidad: element.utilidad,
                UtilCosto: element.ulticosto,
                Vendedor: element.nombreven,
            };

            // Sumamos los valores para los totales
            totalCantidad += element.cantidad;
            totalCosto += element.costo;
            totalValorVenta += element.valorventa;
            totalUtilidad += element.utilidad;
            totalUtilCosto += element.ulticosto;

            _ventas.push(venn);
        });

        // Agregamos la fila de totales
        _ventas.push({ 
            Referencia: "Totales",
            Descripcion: "",
            Cantidad: totalCantidad,
            Costo: totalCosto,
            ValorVenta: totalValorVenta,
            Utilidad: totalUtilidad,
            UtilCosto: totalUtilCosto,
            Vendedor: ""
        });
        var ws = XLSX.utils.json_to_sheet(_ventas);

        // Configuramos el formato de las celdas como tipo moneda (Costo, ValorVenta, Utilidad, UtilCosto)
        const range = XLSX.utils.decode_range(ws['!ref']);

        // Establecemos el formato de las celdas numéricas en el rango de filas y columnas
        for (let R = range.s.r + 1; R <= range.e.r; ++R) {
            // Formato moneda para las columnas Costo, ValorVenta, Utilidad, UtilCosto (índices 3, 4, 5, 6)
            ws[XLSX.utils.encode_cell({ r: R, c: 3 })].z = "$#,##0.00"; // Costo
            ws[XLSX.utils.encode_cell({ r: R, c: 4 })].z = "$#,##0.00"; // ValorVenta
            ws[XLSX.utils.encode_cell({ r: R, c: 5 })].z = "$#,##0.00"; // Utilidad
            ws[XLSX.utils.encode_cell({ r: R, c: 6 })].z = "$#,##0.00"; // UtilCosto
        }

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Catalogo Productos.xlsx");
    }
    const columns = [
        {
            name: 'Referencia',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Descripcion',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: 'Cantidad',
            selector: row => fm4.from(row.cantidad),
            sortable: true,
        },
        {
            name: 'Costo',
            selector: row => fm.from(row.costo),
            sortable: true,
        },
        {
            name: 'Valor Venta',
            selector: row => fm.from(row.valorventa),
            sortable: true,
        },
        {
            name: 'Utilidad',
            selector: row => fm.from(row.utilidad),
            sortable: true,
        },
        {
            name: 'Util/Costo',
            selector: row => fm2.from(row.ulticosto),
            sortable: true,
        },
        {
            name: 'Util/Venta',
            selector: row => fm2.from(row.ultiventa),
            sortable: true,
        },
        {
            name: 'Vendedor',
            selector: row => row.nombreven,
            sortable: true,
        }
    ];
    const totalutilidad = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + (element.utilidad)




        }
        return tot
    }
    const totalvalorventa = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + (element.valorventa)




        }
        return tot
    }
    const totalcosto = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + (element.costo)




        }
        return tot
    }
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        {/* <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                            Costo de Mercancia Vendida
                        </CardHeader> */}
                        <CardBody>
                            <Row className="align-items-end">

                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                                            selected={fechaInicio}
                                            value={fechaInicio}
                                            onChange={(e) => setFechaInicio(e.target.value)}></Input>

                                    </FormGroup>
                                </Col>

                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Fecha Fin:</Label>
                                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                                            selected={fechaFin}
                                            value={fechaFin}
                                            onChange={(e) => setFechaFin(e.target.value)}></Input>


                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Iva Inc</Label>
                                        <Input value={conivacosto} onChange={(e) => { setconivacosto(e.target.value) }} type="select" style={{ fontSize: '13px' }} name="coniva"  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>

                                        </Input>


                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                                        <Button style={{ backgroundColor: 'darkmagenta' }} size="sm" block onClick={() => {
                                            setfiltros(true)
                                        }}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Filtros
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="primary" size="sm" block onClick={buscar}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                                        </Button>
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={exportarExcel}>
                                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Totales Costo
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totalcosto())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Totales Venta
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totalvalorventa())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Totales Utilidad
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totalutilidad())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col xs={2} onClick={() => {
                                    setvendedor(!vendedor)
                                }}>
                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-8">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Vendedor
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {vendedor &&
                                ventasporvendedor.map((item, index) => {
                                    return <>
                                        {index == 0 && <Row>
                                            <Col xs={3}>
                                                <p style={{ color: 'black' }}>Vendedor</p>
                                            </Col>
                                            <Col xs={3}>
                                                <p style={{ color: 'black' }}>Costo</p>
                                            </Col>
                                            <Col xs={3}>
                                                <p style={{ color: 'black' }}>Venta</p>
                                            </Col>
                                            <Col xs={3}>
                                                <p style={{ color: 'black' }}>Utilidad</p>
                                            </Col>
                                        </Row>}
                                        <Row >
                                            <Col xs={3}>
                                                <div className="card border-left-success shadow">
                                                    <div className="card-body  p-2">
                                                        <div className="row no-gutters align-items-center">
                                                            <div className="col mr-2">
                                                                <div className="row no-gutters align-items-center">
                                                                    <div className="col-auto">
                                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.vendedor}</div>
                                                                    </div>
                                                                    <div className="col">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Col>
                                            <Col xs={3} >

                                                <div className="card border-left-primary shadow ">
                                                    <div className="card-body p-2">
                                                        <div className="row no-gutters align-items-center">
                                                            <div className="col mr-2">
                                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.costo)}</div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={3} >

                                                <div className="card border-left-primary shadow ">
                                                    <div className="card-body p-2">
                                                        <div className="row no-gutters align-items-center">
                                                            <div className="col mr-2">
                                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.valorventa)}</div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={3} >

                                                <div className="card border-left-primary shadow ">
                                                    <div className="card-body p-2">
                                                        <div className="row no-gutters align-items-center">
                                                            <div className="col mr-2">
                                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.utilidad)}</div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>


                                        </Row>
                                    </>
                                })}
                            <hr></hr>
                            <Row>

                                <Col sm="12">
                                    <DataTable
                                        progressPending={pendiente}
                                        columns={columns}
                                        data={ventas}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}

                                    />


                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
                <Filtro
                    vendedorinicioseleccionado={vendedorinicioseleccionado}
                    vendedorfinseleccionado={vendedorfinseleccionado}
                    setvendedorinicioseleccionado={(e) => { setvendedorinicioseleccionado(e) }}
                    setvendedorfinseleccionado={(e) => { setvendedorfinseleccionado(e) }}

                    setmayora={(e) => { setmayora(e) }}
                    settipodocumentoinicioseleccionado={(e) => { settipodocumentoinicioseleccionado(e) }}
                    settipodocumentofinseleccionado={(e) => { settipodocumentofinseleccionado(e) }}
                    setclasificacionarticulo1={(e) => { setclasificacionarticulo1(e) }}
                    mayora={mayora}
                    coincidencianombre={coincidencianombre}
                    setterceroinicioseleccionado={(e) => { setterceroinicioseleccionado(e) }}
                    settercerofinseleccionado={(e) => { settercerofinseleccionado(e) }}
                    sucursal={sucursal}
                    setsucursal={(e) => { setsucursal(e) }}
                    terceroinicioseleccionado={terceroinicioseleccionado}
                    tercerofinseleccionado={tercerofinseleccionado}
                    tipodocumentoinicioseleccionado={tipodocumentoinicioseleccionado}
                    tipodocumentofinseleccionado={tipodocumentofinseleccionado}
                    referenciafinseleccionado={referenciafinseleccionado}
                    referenciainicioseleccionado={referenciainicioseleccionado}
                    clasificacionarticulo1={clasificacionarticulo1}
                    setcoincidencianombre={(e) => { setcoincidencianombre(e) }}
                    setreferenciafinseleccionado={(e) => { setreferenciafinseleccionado(e) }}
                    setreferenciainicioseleccionado={(e) => { setreferenciainicioseleccionado(e) }}
                    servidor={props.servidor} verModal={filtros} cerrarModal={() => { setfiltros(false) }} />
            </Row>


        </>
    )
}

export default ReporteVenta;
import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import { Autocomplete, TextField } from '@mui/material';
import * as XLSX from "xlsx"

const modeloCaclientes = {
    tercero: "cliente",
    clase: "13",
    codigo: "",
    cedula: "",
    digito: "",
    razsoc: "",
    nom1: "",
    nom2: "",
    ape1: "",
    ape2: "",
    codigomun: "",
    coddepar: "",
    codpais: "",
    email: "",
    contacto: "",
    beneficiario: "",
    repleg: "",
    reg_merc: "",
    direccion: "",
    tel1: "",
    tel2: "",
    tel3: "",
    barrio: "",
    fechnac: "",
    interes: "",
    cupocre: "",
    plazo: "",
    vendedor: "",
    ccosto: "",
    precsug: "",
    actecono: "",
    feching: "",
    fechulne: "",
    regtrib: "R-99-PN",
    resfis: "R-99",
    desccom: "",
    desccom2: "",
    desccom3: "",
    desccom4: "",
    autoreteft: "",
    estado: "",
    salant1: "",
    saldeb1: "",
    salcre1: "",
    venretefuente: "",
    venbasefte: "",
    venporcfte: "",
    veniva: "",
    venbaseiva: "",
    venporciva: "",
    venreteiva: "",
    venbasereteiva: "",
    venporcreteiva: "",
    venreteica: "",
    venbaseica: "",
    venporica: "",
    salact1: "",
    ciudad: "",
    pais: "",
    clas1: "",
    clas2: "",
    clas3: "",
    clas4: "",
    clas5: "",
    reteica: "",
    personat: "",
    auto: "",
    salant2: "",
    saldeb2: "",
    salcre2: "",
    salact2: "",
    brutop1: "",
    brutop2: "",
    ivap: "",
    desctop1: "",
    desctop2: "",
    reteftep: "",
    reteivap: "",
    reteica2p: "",
    imptoconsumop: "",
    brutoa1: "",
    brutoa2: "",
    ivaa: "",
    desctoa1: "",
    desctoa2: "",
    retftea: "",
    reteivaa: "",
    reteicaa: "",
    imptoconsa: "",
    creadopor: "",
    borradopor: "",
    modificpor: "",
    municipio: "",
    cambioimp: "",
    flete: "",
    cumplemes: "",
    cumpledia: "",
    cumpleano: "",
    horafecha: "",
    sexo: "",
    zona: "",
    clas6: "",
    clas7: "",
    clas8: "",
    clas9: "",
    clas10: "",
    autorexpro: 1,
    cod_postal: "",
    nompais: "",
    campo: "",
    comretefte: "",
    combasefte: "",
    comporcfte: "",
    comreteiva: "",
    combasereteiva: "",
    comporcreteiva: "",
    comiva: "",
    combaseiva: "",
    comporciva: "",
    comreteica: "",
    combaseica: "",
    comporica: "",
    vencree: "",
    venbacree: "",
    venporcree: "",
    comcree: "",
    combacree: "",
    comporcree: "",
    Eliminado: "",
    cuentaiva: "",
    cuentafte: "",
    cuentareteiva: "",
    cuentaica: "",
    cuentacomiva: "",
    cuentacomfte: "",
    cuentacomreteiva: "",
    cuentacomica: "",
    venbasereteredb: "",
    venporreteredb: "",
    cuentareteredb: "",
    venbasereterecr: "",
    venporreterecr: "",
    cuentareterecr: "",
}
const Caclientes = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Caclientes, setCaclientes] = useState(modeloCaclientes);
    const [Caclientess, setCaclientess] = useState([]);
    const [tercero, settercero] = useState([]);
    const [terceros, setterceros] = useState([]);
    const [clase, setclase] = useState([]);
    const [clases, setclases] = useState([]);
    const [codigo, setcodigo] = useState([]);
    const [codigos, setcodigos] = useState([]);
    const [cedula, setcedula] = useState([]);
    const [cedulas, setcedulas] = useState([]);
    const [digito, setdigito] = useState([]);
    const [digitos, setdigitos] = useState([]);
    const [razsoc, setrazsoc] = useState([]);
    const [razsocs, setrazsocs] = useState([]);
    const [nom1, setnom1] = useState([]);
    const [nom1s, setnom1s] = useState([]);
    const [nom2, setnom2] = useState([]);
    const [nom2s, setnom2s] = useState([]);
    const [ape1, setape1] = useState([]);
    const [ape1s, setape1s] = useState([]);
    const [ape2, setape2] = useState([]);
    const [ape2s, setape2s] = useState([]);
    const [codigomun, setcodigomun] = useState([]);
    const [codigomuns, setcodigomuns] = useState([]);
    const [busquedacodigomuns, setbusquedacodigomuns] = useState([]);
    const [verModalcodigomun, setVerModalcodigomun] = useState(false);
    const [coddepar, setcoddepar] = useState([]);
    const [coddepars, setcoddepars] = useState([]);
    const [codpais, setcodpais] = useState([]);
    const [codpaiss, setcodpaiss] = useState([]);
    const [email, setemail] = useState([]);
    const [emails, setemails] = useState([]);
    const [contacto, setcontacto] = useState([]);
    const [contactos, setcontactos] = useState([]);
    const [beneficiario, setbeneficiario] = useState([]);
    const [beneficiarios, setbeneficiarios] = useState([]);
    const [repleg, setrepleg] = useState([]);
    const [replegs, setreplegs] = useState([]);
    const [reg_merc, setreg_merc] = useState([]);
    const [reg_mercs, setreg_mercs] = useState([]);
    const [direccion, setdireccion] = useState([]);
    const [direccions, setdireccions] = useState([]);
    const [tel1, settel1] = useState([]);
    const [tel1s, settel1s] = useState([]);
    const [tel2, settel2] = useState([]);
    const [tel2s, settel2s] = useState([]);
    const [tel3, settel3] = useState([]);
    const [tel3s, settel3s] = useState([]);
    const [barrio, setbarrio] = useState([]);
    const [barrios, setbarrios] = useState([]);
    const [fechnac, setfechnac] = useState([]);
    const [fechnacs, setfechnacs] = useState([]);
    const [interes, setinteres] = useState([]);
    const [interess, setinteress] = useState([]);
    const [cupocre, setcupocre] = useState([]);
    const [cupocres, setcupocres] = useState([]);
    const [plazo, setplazo] = useState([]);
    const [plazos, setplazos] = useState([]);
    const [vendedor, setvendedor] = useState([]);
    const [vendedors, setvendedors] = useState([]);
    const [busquedavendedors, setbusquedavendedors] = useState([]);
    const [verModalvendedor, setVerModalvendedor] = useState(false);
    const [ccosto, setccosto] = useState([]);
    const [ccostos, setccostos] = useState([]);
    const [busquedaccostos, setbusquedaccostos] = useState([]);
    const [verModalccosto, setVerModalccosto] = useState(false);
    const [precsug, setprecsug] = useState([]);
    const [precsugs, setprecsugs] = useState([]);
    const [busquedaprecsugs, setbusquedaprecsugs] = useState([]);
    const [verModalprecsug, setVerModalprecsug] = useState(false);
    const [actecono, setactecono] = useState([]);
    const [acteconos, setacteconos] = useState([]);
    const [feching, setfeching] = useState([]);
    const [fechings, setfechings] = useState([]);
    const [fechulne, setfechulne] = useState([]);
    const [fechulnes, setfechulnes] = useState([]);
    const [regtrib, setregtrib] = useState([]);
    const [regtribs, setregtribs] = useState([]);
    const [resfis, setresfis] = useState([]);
    const [resfiss, setresfiss] = useState([]);
    const [desccom, setdesccom] = useState([]);
    const [desccoms, setdesccoms] = useState([]);
    const [desccom2, setdesccom2] = useState([]);
    const [desccom2s, setdesccom2s] = useState([]);
    const [desccom3, setdesccom3] = useState([]);
    const [desccom3s, setdesccom3s] = useState([]);
    const [desccom4, setdesccom4] = useState([]);
    const [desccom4s, setdesccom4s] = useState([]);
    const [autoreteft, setautoreteft] = useState([]);
    const [autoretefts, setautoretefts] = useState([]);
    const [estado, setestado] = useState([]);
    const [estados, setestados] = useState([]);
    const [salant1, setsalant1] = useState([]);
    const [salant1s, setsalant1s] = useState([]);
    const [saldeb1, setsaldeb1] = useState([]);
    const [saldeb1s, setsaldeb1s] = useState([]);
    const [salcre1, setsalcre1] = useState([]);
    const [salcre1s, setsalcre1s] = useState([]);
    const [venretefuente, setvenretefuente] = useState([]);
    const [venretefuentes, setvenretefuentes] = useState([]);
    const [venbasefte, setvenbasefte] = useState([]);
    const [venbaseftes, setvenbaseftes] = useState([]);
    const [venporcfte, setvenporcfte] = useState([]);
    const [venporcftes, setvenporcftes] = useState([]);
    const [veniva, setveniva] = useState([]);
    const [venivas, setvenivas] = useState([]);
    const [venbaseiva, setvenbaseiva] = useState([]);
    const [venbaseivas, setvenbaseivas] = useState([]);
    const [venporciva, setvenporciva] = useState([]);
    const [venporcivas, setvenporcivas] = useState([]);
    const [venreteiva, setvenreteiva] = useState([]);
    const [venreteivas, setvenreteivas] = useState([]);
    const [venbasereteiva, setvenbasereteiva] = useState([]);
    const [venbasereteivas, setvenbasereteivas] = useState([]);
    const [venporcreteiva, setvenporcreteiva] = useState([]);
    const [venporcreteivas, setvenporcreteivas] = useState([]);
    const [venreteica, setvenreteica] = useState([]);
    const [venreteicas, setvenreteicas] = useState([]);
    const [venbaseica, setvenbaseica] = useState([]);
    const [venbaseicas, setvenbaseicas] = useState([]);
    const [venporica, setvenporica] = useState([]);
    const [venporicas, setvenporicas] = useState([]);
    const [salact1, setsalact1] = useState([]);
    const [salact1s, setsalact1s] = useState([]);
    const [ciudad, setciudad] = useState([]);
    const [ciudads, setciudads] = useState([]);
    const [pais, setpais] = useState([]);
    const [paiss, setpaiss] = useState([]);
    const [clas1, setclas1] = useState([]);
    const [clas1s, setclas1s] = useState([]);
    const [clas2, setclas2] = useState([]);
    const [clas2s, setclas2s] = useState([]);
    const [clas3, setclas3] = useState([]);
    const [clas3s, setclas3s] = useState([]);
    const [clas4, setclas4] = useState([]);
    const [clas4s, setclas4s] = useState([]);
    const [clas5, setclas5] = useState([]);
    const [clas5s, setclas5s] = useState([]);
    const [reteica, setreteica] = useState([]);
    const [reteicas, setreteicas] = useState([]);
    const [personat, setpersonat] = useState([]);
    const [personats, setpersonats] = useState([]);
    const [auto, setauto] = useState([]);
    const [autos, setautos] = useState([]);
    const [salant2, setsalant2] = useState([]);
    const [salant2s, setsalant2s] = useState([]);
    const [saldeb2, setsaldeb2] = useState([]);
    const [saldeb2s, setsaldeb2s] = useState([]);
    const [salcre2, setsalcre2] = useState([]);
    const [salcre2s, setsalcre2s] = useState([]);
    const [salact2, setsalact2] = useState([]);
    const [salact2s, setsalact2s] = useState([]);
    const [brutop1, setbrutop1] = useState([]);
    const [brutop1s, setbrutop1s] = useState([]);
    const [brutop2, setbrutop2] = useState([]);
    const [brutop2s, setbrutop2s] = useState([]);
    const [ivap, setivap] = useState([]);
    const [ivaps, setivaps] = useState([]);
    const [desctop1, setdesctop1] = useState([]);
    const [desctop1s, setdesctop1s] = useState([]);
    const [desctop2, setdesctop2] = useState([]);
    const [desctop2s, setdesctop2s] = useState([]);
    const [reteftep, setreteftep] = useState([]);
    const [retefteps, setretefteps] = useState([]);
    const [reteivap, setreteivap] = useState([]);
    const [reteivaps, setreteivaps] = useState([]);
    const [reteica2p, setreteica2p] = useState([]);
    const [reteica2ps, setreteica2ps] = useState([]);
    const [imptoconsumop, setimptoconsumop] = useState([]);
    const [imptoconsumops, setimptoconsumops] = useState([]);
    const [brutoa1, setbrutoa1] = useState([]);
    const [brutoa1s, setbrutoa1s] = useState([]);
    const [brutoa2, setbrutoa2] = useState([]);
    const [brutoa2s, setbrutoa2s] = useState([]);
    const [ivaa, setivaa] = useState([]);
    const [ivaas, setivaas] = useState([]);
    const [desctoa1, setdesctoa1] = useState([]);
    const [desctoa1s, setdesctoa1s] = useState([]);
    const [desctoa2, setdesctoa2] = useState([]);
    const [desctoa2s, setdesctoa2s] = useState([]);
    const [retftea, setretftea] = useState([]);
    const [retfteas, setretfteas] = useState([]);
    const [reteivaa, setreteivaa] = useState([]);
    const [reteivaas, setreteivaas] = useState([]);
    const [reteicaa, setreteicaa] = useState([]);
    const [reteicaas, setreteicaas] = useState([]);
    const [imptoconsa, setimptoconsa] = useState([]);
    const [imptoconsas, setimptoconsas] = useState([]);
    const [creadopor, setcreadopor] = useState([]);
    const [creadopors, setcreadopors] = useState([]);
    const [borradopor, setborradopor] = useState([]);
    const [borradopors, setborradopors] = useState([]);
    const [modificpor, setmodificpor] = useState([]);
    const [modificpors, setmodificpors] = useState([]);
    const [municipio, setmunicipio] = useState([]);
    const [municipios, setmunicipios] = useState([]);
    const [cambioimp, setcambioimp] = useState([]);
    const [cambioimps, setcambioimps] = useState([]);
    const [flete, setflete] = useState([]);
    const [fletes, setfletes] = useState([]);
    const [cumplemes, setcumplemes] = useState([]);
    const [cumplemess, setcumplemess] = useState([]);
    const [cumpledia, setcumpledia] = useState([]);
    const [cumpledias, setcumpledias] = useState([]);
    const [cumpleano, setcumpleano] = useState([]);
    const [cumpleanos, setcumpleanos] = useState([]);
    const [horafecha, sethorafecha] = useState([]);
    const [horafechas, sethorafechas] = useState([]);
    const [sexo, setsexo] = useState([]);
    const [sexos, setsexos] = useState([]);
    const [zona, setzona] = useState([]);
    const [zonas, setzonas] = useState([]);
    const [clas6, setclas6] = useState([]);
    const [clas6s, setclas6s] = useState([]);
    const [clas7, setclas7] = useState([]);
    const [clas7s, setclas7s] = useState([]);
    const [clas8, setclas8] = useState([]);
    const [clas8s, setclas8s] = useState([]);
    const [clas9, setclas9] = useState([]);
    const [clas9s, setclas9s] = useState([]);
    const [clas10, setclas10] = useState([]);
    const [clas10s, setclas10s] = useState([]);
    const [autorexpro, setautorexpro] = useState([]);
    const [autorexpros, setautorexpros] = useState([]);
    const [cod_postal, setcod_postal] = useState([]);
    const [cod_postals, setcod_postals] = useState([]);
    const [nompais, setnompais] = useState([]);
    const [nompaiss, setnompaiss] = useState([]);
    const [campo, setcampo] = useState([]);
    const [campos, setcampos] = useState([]);
    const [comretefte, setcomretefte] = useState([]);
    const [comreteftes, setcomreteftes] = useState([]);
    const [combasefte, setcombasefte] = useState([]);
    const [combaseftes, setcombaseftes] = useState([]);
    const [comporcfte, setcomporcfte] = useState([]);
    const [comporcftes, setcomporcftes] = useState([]);
    const [comreteiva, setcomreteiva] = useState([]);
    const [comreteivas, setcomreteivas] = useState([]);
    const [combasereteiva, setcombasereteiva] = useState([]);
    const [combasereteivas, setcombasereteivas] = useState([]);
    const [comporcreteiva, setcomporcreteiva] = useState([]);
    const [comporcreteivas, setcomporcreteivas] = useState([]);
    const [comiva, setcomiva] = useState([]);
    const [comivas, setcomivas] = useState([]);
    const [combaseiva, setcombaseiva] = useState([]);
    const [combaseivas, setcombaseivas] = useState([]);
    const [comporciva, setcomporciva] = useState([]);
    const [comporcivas, setcomporcivas] = useState([]);
    const [comreteica, setcomreteica] = useState([]);
    const [comreteicas, setcomreteicas] = useState([]);
    const [combaseica, setcombaseica] = useState([]);
    const [combaseicas, setcombaseicas] = useState([]);
    const [comporica, setcomporica] = useState([]);
    const [comporicas, setcomporicas] = useState([]);
    const [vencree, setvencree] = useState([]);
    const [vencrees, setvencrees] = useState([]);
    const [venbacree, setvenbacree] = useState([]);
    const [venbacrees, setvenbacrees] = useState([]);
    const [venporcree, setvenporcree] = useState([]);
    const [venporcrees, setvenporcrees] = useState([]);
    const [comcree, setcomcree] = useState([]);
    const [comcrees, setcomcrees] = useState([]);
    const [combacree, setcombacree] = useState([]);
    const [combacrees, setcombacrees] = useState([]);
    const [comporcree, setcomporcree] = useState([]);
    const [comporcrees, setcomporcrees] = useState([]);
    const [Eliminado, setEliminado] = useState([]);
    const [Eliminados, setEliminados] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalCaclientes, setVerModalCaclientes] = useState(false);
    const [Direcciones, setDirecciones] = useState([]);
    const [Direccioness, setDireccioness] = useState([]);
    const [Plandecuentass, setPlandecuentass] = useState([]);

    const exportarExcel = () => {

        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(Caclientess);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Exportacion Terceros.xlsx")
    }
    const obtenerPlandecuentass = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Plandecuentasapi/ultimonivel?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setPlandecuentass(data)
                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Caclientes = { ...Caclientes }
        _Caclientes[e.target.name] = value
        if (e.target.name == 'cedula') {
            var digitoverificacion = calcularDigitoVerificacion(value)
            _Caclientes['digito'] = digitoverificacion + ""

        }
        if (e.target.name == 'nom1' || e.target.name == 'nom2' || e.target.name == 'ape1' || e.target.name == 'ape2') {
            if ((_Caclientes.clase != '31' && _Caclientes.clase != '50') || _Caclientes.autorexpro == 2) {
                _Caclientes['razsoc'] = _Caclientes.nom1 + " " + _Caclientes.nom2 + " " + _Caclientes.ape1 + " " + _Caclientes.ape2

            }
        }
        if (e.target.name == 'clase') {
            _Caclientes.nom1 = ''
            _Caclientes.nom2 = ''
            _Caclientes.ape1 = ''
            _Caclientes.ape2 = ''
        }
        if (e.target.name == 'codigo') {
            _Caclientes.cedula = value
            var digitoverificacion = calcularDigitoVerificacion(value)
            _Caclientes['digito'] = digitoverificacion + ""
        }
        setCaclientes(_Caclientes)
        setRoles([])


    }

    const handleChangecuentas = (e, values, name) => {
        try {
            if (values) {
                let value = e.target.value
                var ff = values
                console.log(e.target)
                var _Caclientes = { ...Caclientes }
                _Caclientes[name] = values.codcuenta
                setCaclientes(_Caclientes)
                setRoles([])


            } else {
                var _Caclientes = { ...Caclientes }
                _Caclientes[name] = ""
                setCaclientes(_Caclientes)
            }
        } catch (error) {

        }
    }
    function calcularDigitoVerificacion(myNit) {
        var vpri,
            x,
            y,
            z;

        // Se limpia el Nit
        myNit = myNit.replace(/\s/g, ""); // Espacios
        myNit = myNit.replace(/,/g, ""); // Comas
        myNit = myNit.replace(/\./g, ""); // Puntos
        myNit = myNit.replace(/-/g, ""); // Guiones

        // Se valida el nit
        if (isNaN(myNit)) {
            console.log("El nit/cédula '" + myNit + "' no es válido(a).");
            return "";
        };

        // Procedimiento
        vpri = new Array(16);
        z = myNit.length;

        vpri[1] = 3;
        vpri[2] = 7;
        vpri[3] = 13;
        vpri[4] = 17;
        vpri[5] = 19;
        vpri[6] = 23;
        vpri[7] = 29;
        vpri[8] = 37;
        vpri[9] = 41;
        vpri[10] = 43;
        vpri[11] = 47;
        vpri[12] = 53;
        vpri[13] = 59;
        vpri[14] = 67;
        vpri[15] = 71;

        x = 0;
        y = 0;
        for (var i = 0; i < z; i++) {
            y = (myNit.substr(i, 1));
            // console.log ( y + "x" + vpri[z-i] + ":" ) ;

            x += (y * vpri[z - i]);
            // console.log ( x ) ;    
        }

        y = x % 11;
        // console.log ( y ) ;

        return (y > 1) ? 11 - y : y;
    }
    const seleccionarcodigomun = (e) => {
        let value = e
        var _Caclientes = { ...Caclientes }
        _Caclientes['codigomun'] = value.codigomun
        _Caclientes['municipio'] = value.nombremun
        _Caclientes['coddepar'] = value.coddepar
        _Caclientes['pais'] = value.nombredep
        _Caclientes['codpais'] = value.codpais
        _Caclientes['nompais'] = value.nombre
        setCaclientes(_Caclientes)
        setRoles([])
        cerrarModalSeleccioncodigomuns()
    }
    const seleccionarvendedor = (e) => {
        let value = e
        var _Caclientes = { ...Caclientes }
        _Caclientes['vendedor'] = value
        setCaclientes(_Caclientes)
        setRoles([])
        cerrarModalSeleccionvendedors()
    }
    const seleccionarccosto = (e) => {
        let value = e
        var _Caclientes = { ...Caclientes }
        _Caclientes['ccosto'] = value
        setCaclientes(_Caclientes)
        setRoles([])
        cerrarModalSeleccionccostos()
    }
    const seleccionarprecsug = (e) => {
        let value = e
        var _Caclientes = { ...Caclientes }
        _Caclientes['precsug'] = value
        setCaclientes(_Caclientes)
        setRoles([])
        cerrarModalSeleccionprecsugs()
    }
    const buscartodascodigomuns = (value) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/municipioapi/nombres/' + value + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    let dt23232 = JSON.parse(user)

                    setcodigomuns(dataJson)
                    setbusquedacodigomuns(dataJson)
                    try {
                        var munnn = dt.empresa.config.direccion_contribuyente_municipio.replace(dt.empresa.config.direccion_contribuyente_departamento, "")
                        var value = dataJson.find(p => p.codigomun.trim() == munnn.trim()
                            && p.coddepar.trim() == dt.empresa.config.direccion_contribuyente_departamento.trim())
                        if (value && (!editar)) {
                            var _Caclientes = { ...Caclientes }
                            _Caclientes['codigomun'] = value.codigomun
                            _Caclientes['municipio'] = value.nombremun
                            _Caclientes['coddepar'] = value.coddepar
                            _Caclientes['pais'] = value.nombredep
                            _Caclientes['codpais'] = value.codpais
                            _Caclientes['nompais'] = value.nombre
                            setCaclientes(_Caclientes)
                        }

                    } catch (error2) {
                        console.log(error2)
                    }
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setcodigomuns([])
                    setbusquedacodigomuns([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const buscartodasvendedors = (value) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Vendedorapi/nombres/' + value + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setvendedors(dataJson)
                    setbusquedavendedors(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setvendedors([])
                    setbusquedavendedors([])
                })
        } catch (error) {
            console.log(error)
        }
    }

    const buscartodasccostos = (value) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Ctcentrocostoapi/nombres/' + value + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setccostos(dataJson)
                    setbusquedaccostos(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setccostos([])
                    setbusquedaccostos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const buscartodasprecsugs = (value) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/ListadePrecioapi/nombres/' + value + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setprecsugs(dataJson)
                    setbusquedaprecsugs(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setprecsugs([])
                    setbusquedaprecsugs([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerCaclientess = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            if (coincidencia == '') {
                let response = await fetch(props.servidor + '/Caclientesapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');
                if (response.ok) {
                    let data = await response.json()
                    setCaclientess(data)
                    // var _direcciones = data.map((dato) => {
                    //   if (dato.direcciones.length!=0) {
                    //     for (let index = 0; index < dato.direcciones.length; index++) {
                    //         const element = dato.direcciones[index];
                    //         return element

                    //     }
                    //   }else{
                    //     return {
                    //         tercero:dato.caclientes.codigo,
                    //         direccion:""
                    //     }
                    //   }
                    // })
                    setPendiente(false)
                }
            } else {
                let response = await fetch(props.servidor + '/Caclientesapi/unicocoincidecnias/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');
                if (response.ok) {
                    let data = await response.json()
                    setCaclientess(data)
                    // var _direcciones = data.map((dato) => {
                    //   if (dato.direcciones.length!=0) {
                    //     for (let index = 0; index < dato.direcciones.length; index++) {
                    //         const element = dato.direcciones[index];
                    //         return element

                    //     }
                    //   }else{
                    //     return {
                    //         tercero:dato.caclientes.codigo,
                    //         direccion:""
                    //     }
                    //   }
                    // })
                    setPendiente(false)
                }

            }


        } catch (error) {
            console.log(error)
        }
    }
    const borrartodaslascodigomuns = () => {
        setbusquedacodigomuns([])
    }
    const borrartodaslasvendedors = () => {
        setbusquedavendedors([])
    }
    const borrartodaslasprecsugs = () => {
        setbusquedaprecsugs([])
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerCaclientess('');
        buscartodascodigomuns('');
        buscartodasvendedors('');
        buscartodasccostos('');
        buscartodasprecsugs('');
        obtenerPlandecuentass('');
    }, [])
    const filtrarcodigomuns = async (coincidencia) => {
        var _codigomuns = codigomuns.filter((dato) => {
            if (dato.codigomun.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombremun.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedacodigomuns(_codigomuns)
    }
    const filtrarvendedors = async (coincidencia) => {
        var _vendedors = vendedors.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedavendedors(_vendedors)
    }
    const filtrarccostos = async (coincidencia) => {
        var _ccostos = ccostos.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedaccostos(_ccostos)
    }

    const filtrarprecsugs = async (coincidencia) => {
        var _precsugs = precsugs.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedaprecsugs(_precsugs)
    }
    const columns = [
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalCaclientes(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
        {
            name: 'Tipo Tercero',
            selector: row => row.tercero,
            sortable: true,
        },
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Documento',
            selector: row => row.cedula,
            sortable: true,
        },
        {
            name: 'Razon Social',
            selector: row => row.razsoc,
            sortable: true,
        },

    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalCaclientes = async (data) => {
        var enviodata = { ...data }
        var _Caclientes = {
            tercero: enviodata.tercero,
            clase: enviodata.clase,
            codigo: enviodata.codigo,
            cedula: enviodata.cedula,
            digito: enviodata.digito,
            razsoc: enviodata.razsoc,
            nom1: enviodata.nom1,
            nom2: enviodata.nom2,
            ape1: enviodata.ape1,
            ape2: enviodata.ape2,
            codigomun: enviodata.codigomun,
            coddepar: enviodata.coddepar,
            codpais: enviodata.codpais,
            email: enviodata.email,
            contacto: enviodata.contacto,
            beneficiario: enviodata.beneficiario,
            repleg: enviodata.repleg,
            reg_merc: enviodata.reg_merc,
            direccion: enviodata.direccion,
            tel1: enviodata.tel1,
            tel2: enviodata.tel2,
            tel3: enviodata.tel3,
            barrio: enviodata.barrio,
            fechnac: enviodata.fechnac,
            interes: enviodata.interes,
            cupocre: enviodata.cupocre,
            plazo: enviodata.plazo,
            vendedor: enviodata.vendedor,
            ccosto: enviodata.ccosto,
            precsug: enviodata.precsug,
            actecono: enviodata.actecono,
            feching: enviodata.feching,
            fechulne: enviodata.fechulne,
            regtrib: enviodata.regtrib,
            resfis: enviodata.resfis,
            desccom: enviodata.desccom,
            desccom2: enviodata.desccom2,
            desccom3: enviodata.desccom3,
            desccom4: enviodata.desccom4,
            autoreteft: enviodata.autoreteft,
            estado: enviodata.estado,
            salant1: enviodata.salant1,
            saldeb1: enviodata.saldeb1,
            salcre1: enviodata.salcre1,
            venretefuente: enviodata.venretefuente,
            venbasefte: enviodata.venbasefte,
            venporcfte: enviodata.venporcfte,
            veniva: enviodata.veniva,
            venbaseiva: enviodata.venbaseiva,
            venporciva: enviodata.venporciva,
            venreteiva: enviodata.venreteiva,
            venbasereteiva: enviodata.venbasereteiva,
            venporcreteiva: enviodata.venporcreteiva,
            venreteica: enviodata.venreteica,
            venbaseica: enviodata.venbaseica,
            venporica: enviodata.venporica,
            salact1: enviodata.salact1,
            ciudad: enviodata.ciudad,
            pais: enviodata.pais,
            clas1: enviodata.clas1,
            clas2: enviodata.clas2,
            clas3: enviodata.clas3,
            clas4: enviodata.clas4,
            clas5: enviodata.clas5,
            reteica: enviodata.reteica,
            personat: enviodata.personat,
            auto: enviodata.auto,
            salant2: enviodata.salant2,
            saldeb2: enviodata.saldeb2,
            salcre2: enviodata.salcre2,
            salact2: enviodata.salact2,
            brutop1: enviodata.brutop1,
            brutop2: enviodata.brutop2,
            ivap: enviodata.ivap,
            desctop1: enviodata.desctop1,
            desctop2: enviodata.desctop2,
            reteftep: enviodata.reteftep,
            reteivap: enviodata.reteivap,
            reteica2p: enviodata.reteica2p,
            imptoconsumop: enviodata.imptoconsumop,
            brutoa1: enviodata.brutoa1,
            brutoa2: enviodata.brutoa2,
            ivaa: enviodata.ivaa,
            desctoa1: enviodata.desctoa1,
            desctoa2: enviodata.desctoa2,
            retftea: enviodata.retftea,
            reteivaa: enviodata.reteivaa,
            reteicaa: enviodata.reteicaa,
            imptoconsa: enviodata.imptoconsa,
            creadopor: enviodata.creadopor,
            borradopor: enviodata.borradopor,
            modificpor: enviodata.modificpor,
            municipio: enviodata.municipio,
            cambioimp: enviodata.cambioimp,
            flete: enviodata.flete,
            cumplemes: enviodata.cumplemes,
            cumpledia: enviodata.cumpledia,
            cumpleano: enviodata.cumpleano,
            horafecha: enviodata.horafecha,
            sexo: enviodata.sexo,
            zona: enviodata.zona,
            clas6: enviodata.clas6,
            clas7: enviodata.clas7,
            clas8: enviodata.clas8,
            clas9: enviodata.clas9,
            clas10: enviodata.clas10,
            autorexpro: enviodata.autorexpro,
            cod_postal: enviodata.cod_postal,
            nompais: enviodata.nompais,
            campo: enviodata.campo,
            comretefte: enviodata.comretefte,
            combasefte: enviodata.combasefte,
            comporcfte: enviodata.comporcfte,
            comreteiva: enviodata.comreteiva,
            combasereteiva: enviodata.combasereteiva,
            comporcreteiva: enviodata.comporcreteiva,
            comiva: enviodata.comiva,
            combaseiva: enviodata.combaseiva,
            comporciva: enviodata.comporciva,
            comreteica: enviodata.comreteica,
            combaseica: enviodata.combaseica,
            comporica: enviodata.comporica,
            vencree: enviodata.vencree,
            venbacree: enviodata.venbacree,
            venporcree: enviodata.venporcree,
            comcree: enviodata.comcree,
            combacree: enviodata.combacree,
            comporcree: enviodata.comporcree,
            Eliminado: enviodata.Eliminado,
            Eliminado: enviodata.Eliminado,
            cuentaiva: enviodata.cuentaiva,
            cuentafte: enviodata.cuentafte,
            cuentareteiva: enviodata.cuentareteiva,
            cuentaica: enviodata.cuentaica,
            cuentacomiva: enviodata.cuentacomiva,
            cuentacomfte: enviodata.cuentacomfte,
            cuentacomreteiva: enviodata.cuentacomreteiva,
            cuentacomica: enviodata.cuentacomica,

            venbasereteredb: enviodata.venbasereteredb,
            venporreteredb: enviodata.venporreteredb,
            cuentareteredb: enviodata.cuentareteredb,
            venbasereterecr: enviodata.venbasereterecr,
            venporreterecr: enviodata.venporreterecr,
            cuentareterecr: enviodata.cuentareterecr,

        }
        var clientecompleto = await buscarcaclientesunico(enviodata.codigo)
        var _Direcciones = clientecompleto.direcciones
        if (clientecompleto.direcciones.length == 0) {
            _Direcciones = [{
                tercero: clientecompleto.caclientes.codigo,
                direccion: ""
            }]
        }
        setCaclientes(_Caclientes);
        setDirecciones(_Direcciones);
        setVerModalCaclientes(!verModalCaclientes);
        seteditar(true)
    }

    const buscarcaclientesunico = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unico/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const agregarvendedorcomisionesrecaudo = async (e, index) => {

        var _Vendedor = Direcciones.map((dato) => { return dato })
        _Vendedor.push({
            direccion: "",
            tercero: "",
        })

        setDirecciones(_Vendedor)
        setRoles([])

    }
    const buscarcaclientesunico2 = async (coincidencia, tercc) => {
        try {
            var tipotercero = tercc


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unico/' + coincidencia + '/' + tipotercero + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const cerrarModalCaclientes = () => {
        setCaclientes(modeloCaclientes)
        setVerModalCaclientes(!verModalCaclientes);
        seteditar(false)
        setDirecciones([])
        setKey('Datos')
    }
    const cerrarModalSeleccioncodigomuns = () => {
        setVerModalcodigomun(!verModalcodigomun);
        setbusquedacodigomuns(codigomuns)
    }
    const cerrarModalSeleccionvendedors = () => {
        setVerModalvendedor(!verModalvendedor);
        setbusquedavendedors(vendedors)
    }
    const cerrarModalSeleccionccostos = () => {
        setVerModalccosto(!verModalccosto);
        setbusquedaccostos(ccostos)
    }
    const cerrarModalSeleccionprecsugs = () => {
        setVerModalprecsug(!verModalprecsug);
        setbusquedaprecsugs(precsugs)
    }
    const asignarceros = (_Caclientes) => {

        let claves = Object.keys(_Caclientes);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'interes' || clave == 'cupocre' || clave == 'plazo' || clave == 'desccom' || clave == 'desccom2' || clave == 'desccom3' || clave == 'desccom4' || clave == 'salant1' || clave == 'saldeb1' || clave == 'salcre1' || clave == 'venretefuente' || clave == 'venbasefte' || clave == 'venporcfte' || clave == 'veniva' || clave == 'venbaseiva' || clave == 'venporciva' || clave == 'venreteiva' || clave == 'venbasereteiva' || clave == 'venporcreteiva' || clave == 'venreteica' || clave == 'venbaseica' || clave == 'venporica' || clave == 'salact1' || clave == 'reteica' || clave == 'personat' || clave == 'auto' || clave == 'salant2' || clave == 'saldeb2' || clave == 'salcre2' || clave == 'salact2' || clave == 'brutop1' || clave == 'brutop2' || clave == 'ivap' || clave == 'desctop1' || clave == 'desctop2' || clave == 'reteftep' || clave == 'reteivap' || clave == 'reteica2p' || clave == 'imptoconsumop' || clave == 'brutoa1' || clave == 'brutoa2' || clave == 'ivaa' || clave == 'desctoa1' || clave == 'desctoa2' || clave == 'retftea' || clave == 'reteivaa' || clave == 'reteicaa' || clave == 'imptoconsa' || clave == 'cambioimp' || clave == 'flete' || clave == 'cumpledia' || clave == 'sexo' || clave == 'zona' || clave == 'autorexpro' || clave == 'comretefte' || clave == 'combasefte' || clave == 'comporcfte' || clave == 'comreteiva' || clave == 'combasereteiva' || clave == 'comporcreteiva' || clave == 'comiva' || clave == 'combaseiva' || clave == 'comporciva' || clave == 'comreteica' || clave == 'combaseica' || clave == 'comporica' || clave == 'vencree' || clave == 'venbacree' || clave == 'venporcree' || clave == 'comcree' || clave == 'combacree' || clave == 'comporcree') {
                _Caclientes[clave] = Number(_Caclientes[clave])
            }
        }
        return _Caclientes
    }

    const verificar = (_Caclientes) => {

        let claves = Object.keys(_Caclientes);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'tercero' || clave == 'clase' || clave == 'codigo' || clave == 'cedula' || clave == 'razsoc' || clave == 'codigomun' || clave == 'coddepar' || clave == 'codpais') {
                if (_Caclientes[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const guardarCambios = async () => {
        let response;
        console.log(Caclientes)
        var _Caclientes = { ...Caclientes }

        var _Direcciones = Direcciones.map((dato) => { return dato })
        _Direcciones = _Direcciones.map((dato) => {
            var dat = {
                ...dato,
                ['tercero']: _Caclientes.codigo
            }
            return dat
        })
        _Caclientes = asignarceros(_Caclientes)
        var completo = {
            caclientes: _Caclientes,
            direcciones: _Direcciones
        }
        var _verificado = verificar(_Caclientes)
        if (_verificado) {
            console.log(_Caclientes)
            if (!editar) {
                var terrr = await buscarcaclientesunico2(_Caclientes.codigo, _Caclientes.tercero)
                if (terrr.caclientes.codigo != "") {
                    Swal.fire(
                        'Oops!',
                        'Tercero ya Existe',
                        'error'
                    )
                    return
                }
                response = await fetch("" + props.servidor + "/Caclientesapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(completo)
                })

            } else {
                response = await fetch("" + props.servidor + "/Caclientesapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(completo)
                })
            }

            if (response.ok) {
                if (usuario.empresa.modulos.ARTICULOS.electronico) {
                    await obtenerCaclientess('');
                }

                setCaclientes(modeloCaclientes)
                setVerModalCaclientes(!verModalCaclientes);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Tercero fue guardado exitosamente',
                    'success'
                )
                try {
                    props.escribirenterceromov(_Caclientes.codigo)
                    props.setcreartercero(false)
                } catch (error) {

                }
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                var errores = jsonerrores.errors
                let claves = Object.keys(errores);
                var stringerrores = ""
                for (let i = 0; i < claves.length; i++) {
                    let clave = claves[i];
                    if (errores[clave].length != 0) {
                        stringerrores = stringerrores + errores[clave][0] + " \n "
                    }

                }
                var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                if (mensaje.includes('not be converted to System.Decimal')) {
                    mensaje = 'Hay campos numericos rellenados con letras'
                }
                console.log(stringerrores)
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Terceros
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6" >
                        <div className="input-group input-group-sm mb-3" >
                            <div className="input-group-prepend" >

                                <input type="text" id="buscardataCaclientess" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerCaclientess(document.getElementById('buscardataCaclientess').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalCaclientes(!verModalCaclientes)
                                seteditar(false)
                                buscartodascodigomuns('')
                                buscartodasvendedors('')
                                buscartodasprecsugs('')
                                obtenerPlandecuentass('');
                            }}>Nuevo</Button>
                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setcreartercero(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Button color="success" size="sm" block onClick={exportarExcel}>
                                        <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                                    </Button>
                                </FormGroup>
                            </Col>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Caclientess}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" style={{ maxWidth: '1600px', width: '85%' }} isOpen={verModalCaclientes}>
                <ModalHeader>
                    Tercero
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label>Tipo Tercero*</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="tercero" value={Caclientes.tercero}  >
                                            <option value="cliente">Cliente</option>
                                            <option value="proveedor">Proveedor/Otro</option>
                                            <option value="asesor">Asesor</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Clase*</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="clase" value={Caclientes.clase}  >
                                            <option value="31">Nit</option>
                                            <option value="13">Cedula</option>
                                            <option value="22">Cedula Extranjeria</option>
                                            <option value="41">Pasaporte</option>
                                            <option value="11">Registro Civil</option>
                                            <option value="12">Tarjeta de Identidad</option>
                                            <option value="42">Documento de Identifiacion Extranjero</option>
                                            <option value="10">Tarjeta de Extranjeria</option>
                                            <option value="91">NUIP</option>
                                            <option value="47">Permiso Especial de Permanencia (PEP)</option>
                                            <option value="13">Sin Identificación del Exterior o para uso Definido por la DIAN</option>
                                            <option value="50">Nit de otro País</option>
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Codigo*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Caclientes.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Documento*</Label>

                                        <InputGroup size="sm" >
                                            <Input type="text" bsSize="lg" onChange={handleChange} name="cedula" value={Caclientes.cedula} />

                                            <Col sm={3}>
                                                <Input type="text" disabled bsSize="sm" name="digito" value={Caclientes.digito} />
                                            </Col>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col sm={4}>
                                        <FormGroup>
                                            <Label>Tipo Persona*</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="autorexpro" value={Caclientes.autorexpro}  >
                                                <option value={1}>Persona Juridica</option>
                                                <option value={2}>Persona Natural</option>
                                            </Input>
                                        </FormGroup>
                                </Col>
                            </Row>
                            <Row>

                                <Col sm={12}>
                                    <FormGroup>
                                        <Label>Razon Social*</Label>
                                        <Input disabled={((Caclientes.clase != '31' && Caclientes.clase != '50') || Caclientes.autorexpro == 2)} type="text" bsSize="sm" onChange={handleChange} name="razsoc" value={Caclientes.razsoc} />
                                    </FormGroup>
                                </Col>
                                {
                                    ((Caclientes.clase != '31' && Caclientes.clase != '50') || Caclientes.autorexpro == 2) &&
                                    <>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Nombre 1</Label>
                                                <Input type="text" bsSize="sm" onChange={handleChange} name="nom1" value={Caclientes.nom1} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Nombre 2</Label>
                                                <Input type="text" bsSize="sm" onChange={handleChange} name="nom2" value={Caclientes.nom2} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Apellido 1</Label>
                                                <Input type="text" bsSize="sm" onChange={handleChange} name="ape1" value={Caclientes.ape1} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Apellido 2</Label>
                                                <Input type="text" bsSize="sm" onChange={handleChange} name="ape2" value={Caclientes.ape2} />
                                            </FormGroup>
                                        </Col>
                                    </>

                                }

                            </Row>
                            <Row>

                                <Col sm={3}>
                                    <FormGroup inline={true}>
                                        <Label>Municipio*</Label>
                                        <InputGroup>
                                            <Input bsSize="sm" onKeyDown={(e) => {
                                                if (e.key == "Enter" && e.target.value == "") {
                                                    setbusquedacodigomuns(codigomuns)
                                                    setVerModalcodigomun(true)

                                                }
                                            }} name="codigomun" value={Caclientes.municipio} />
                                            <Button color="primary" size="sm" className="mr-2"
                                                onClick={() => {
                                                    setbusquedacodigomuns(codigomuns)
                                                    setVerModalcodigomun(true)
                                                }}
                                            >
                                                <i className="fas fa-pen-alt"></i>
                                            </Button>
                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label>Departamento*</Label>
                                        <Input disabled type="text" bsSize="sm" onChange={handleChange} name="coddepar" value={Caclientes.pais} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label>Pais*</Label>
                                        <Input disabled type="text" bsSize="sm" onChange={handleChange} name="codpais" value={Caclientes.nompais} />
                                    </FormGroup>
                                </Col>
                                <Col sm={5}>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="email" value={Caclientes.email} />
                                    </FormGroup>
                                </Col>
                            </Row>


                        </Tab>
                        <Tab eventKey="Datos Adicionales" title="Datos Adicionales">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Contacto</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="contacto" value={Caclientes.contacto} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>{(Caclientes.tercero == "cliente") ? "Contacto 2" : "Beneficiario"}</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="beneficiario" value={Caclientes.beneficiario} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Representante Legal</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="repleg" value={Caclientes.repleg} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Registro Mercantil</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="reg_merc" value={Caclientes.reg_merc} />
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Telefono 1</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="tel1" value={Caclientes.tel1} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Telefono 2</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="tel2" value={Caclientes.tel2} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Telefono 3</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="tel3" value={Caclientes.tel3} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Barrio</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="barrio" value={Caclientes.barrio} />
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col sm={5}>
                                    <FormGroup>
                                        <Label>Direccion</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="direccion" value={Caclientes.direccion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label>Interes</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="interes" value={Caclientes.interes} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Cupo Credito</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="cupocre" value={Caclientes.cupocre} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label>Plazo</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="plazo" value={Caclientes.plazo} />
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup inline={true}>
                                        <Label>Vendedor</Label>
                                        <InputGroup>
                                            <Input bsSize="sm" onKeyDown={(e) => {
                                                if (e.key == "Enter" && e.target.value == "") {
                                                    setbusquedavendedors(vendedors)
                                                    setVerModalvendedor(true)

                                                }
                                            }} name="vendedor" value={Caclientes.vendedor} />
                                            <Button color="primary" size="sm" className="mr-2"
                                                onClick={() => {
                                                    setbusquedavendedors(vendedors)
                                                    setVerModalvendedor(true)
                                                }}
                                            >
                                                <i className="fas fa-pen-alt"></i>
                                            </Button>
                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup inline={true}>
                                        <Label>Centro de Costo</Label>
                                        <InputGroup>
                                            <Input bsSize="sm" onKeyDown={(e) => {
                                                if (e.key == "Enter" && e.target.value == "") {
                                                    setbusquedaccostos(ccostos)
                                                    setVerModalccosto(true)

                                                }
                                            }} name="ccosto" value={Caclientes.ccosto} />
                                            <Button color="primary" size="sm" className="mr-2"
                                                onClick={() => {
                                                    setbusquedaccostos(ccostos)
                                                    setVerModalccosto(true)
                                                }}
                                            >
                                                <i className="fas fa-pen-alt"></i>
                                            </Button>
                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup inline={true}>
                                        <Label>Precio Sugerido</Label>
                                        <InputGroup>
                                            <Input bsSize="sm" onKeyDown={(e) => {
                                                if (e.key == "Enter" && e.target.value == "") {
                                                    setbusquedaprecsugs(precsugs)
                                                    setVerModalprecsug(true)

                                                }
                                            }} name="precsug" value={Caclientes.precsug} />
                                            <Button color="primary" size="sm" className="mr-2"
                                                onClick={() => {
                                                    setbusquedaprecsugs(precsugs)
                                                    setVerModalprecsug(true)
                                                }}
                                            >
                                                <i className="fas fa-pen-alt"></i>
                                            </Button>
                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Actividad Economica</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="actecono" value={Caclientes.actecono} />
                                    </FormGroup>
                                </Col>
                                {/* este campo se usa en gas para decirle que articulo(que tipo de gas) se facturara */}
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label>Tipo Articulo</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="campo" value={Caclientes.campo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label>Clasificacion 1</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="clas1" value={Caclientes.clas1} />
                                    </FormGroup>
                                </Col>

                            </Row>

                        </Tab>
                        <Tab eventKey="Datos Especificos" title="Datos Especificos">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha de Nacimiento</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="fechnac" value={Caclientes.fechnac} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Ingreso</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="feching" value={Caclientes.feching} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Ultimo Negocio</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="fechulne" value={Caclientes.fechulne} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Auto Retefuente</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="autoreteft" value={Caclientes.autoreteft}  >
                                            <option value="0">No</option>
                                            <option value="1">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col sm={5}>
                                    <FormGroup>
                                        <Label>Regimen Tributario</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="regtrib" value={Caclientes.regtrib}  >
                                            <option value="O-13">Gran contribuyente</option>
                                            <option value="O-15">Autorretenedor</option>
                                            <option value="O-23">Agente de retención IVA</option>
                                            <option value="O-47">Régimen simple de tributación</option>
                                            <option value="R-99-PN">Otro</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={5}>
                                    <FormGroup>
                                        <Label>Regimen Fiscal</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="resfis" value={Caclientes.resfis}  >
                                            <option value="O-13">Gran contribuyente</option>
                                            <option value="O-15">Autorretenedor</option>
                                            <option value="O-23">Agente de retención IVA</option>
                                            <option value="O-47">Régimen simple de tributación</option>
                                            <option value="O-48">Impuesto sobre las ventas</option>
                                            <option value="O-49">No responsable de IVA</option>
                                            <option value="R-99">PN-No responsable</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label>Estado</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="estado" value={Caclientes.estado}  >
                                            <option value="activo">Activo</option>
                                            <option value="inactivo">Inactivo</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Descuento Comercial</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="desccom" value={Caclientes.desccom} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Descuento Comercial 2</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="desccom2" value={Caclientes.desccom2} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Descuento Comercial 3</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="desccom3" value={Caclientes.desccom3} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Precio FIjo Especial Colegio</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="desccom4" value={Caclientes.desccom4} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/* <Row>

                                <Col sm={12} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                    <p>Saldos</p>

                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Anterior</Label>
                                        <Input disabled type="number" bsSize="sm" onChange={handleChange} name="salant1" value={Caclientes.salant1} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Debito</Label>
                                        <Input disabled type="number" bsSize="sm" onChange={handleChange} name="saldeb1" value={Caclientes.saldeb1} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Credito</Label>
                                        <Input disabled type="number" bsSize="sm" onChange={handleChange} name="salcre1" value={Caclientes.salcre1} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Actual</Label>
                                        <Input disabled type="number" bsSize="sm" onChange={handleChange} name="salact1" value={Caclientes.salact1} />
                                    </FormGroup>
                                </Col>
                            </Row> */}

                        </Tab>
                        {(Caclientes.tercero == "cliente") && <Tab eventKey="Impuestos Ventas" title="Impuestos Ventas">
                            <Row>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                            <p>Iva</p>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Base Minima</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="venbaseiva" value={Caclientes.venbaseiva} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>
                                                <Label>Porcentaje</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="venporciva" value={Caclientes.venporciva} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={7} style={{ marginTop: '4%' }}>
                                            <Autocomplete
                                                onChange={(e, value) => { handleChangecuentas(e, value, 'cuentaiva') }}

                                                disablePortal
                                                options={Plandecuentass}
                                                getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                value={Plandecuentass.filter(p => p.codcuenta == Caclientes.cuentaiva)[0]}
                                                renderInput={(params) => <TextField {...params} size="small" style={{ width: '80%', borderRadius: '4px' }} label="Cuenta" />}
                                                maxHeight={200}
                                            />
                                        </Col>

                                    </Row>

                                </Col>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                            <p>Rete Fuente</p>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Base Minima</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="venbasefte" value={Caclientes.venbasefte} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>
                                                <Label>Porcentaje</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="venporcfte" value={Caclientes.venporcfte} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={7} style={{ marginTop: '4%' }} >
                                            <Autocomplete
                                                onChange={(e, value) => { handleChangecuentas(e, value, 'cuentafte') }}

                                                disablePortal
                                                options={Plandecuentass}
                                                getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                value={Plandecuentass.filter(p => p.codcuenta == Caclientes.cuentafte)[0]}
                                                renderInput={(params) => <TextField {...params} size="small" style={{ width: '80%', borderRadius: '4px' }} label="Cuenta" />}
                                                maxHeight={200}
                                            />
                                        </Col>
                                    </Row>

                                </Col>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                            <p>Rete Iva</p>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Base Minima</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="venbasereteiva" value={Caclientes.venbasereteiva} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>
                                                <Label>Porcentaje</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="venporcreteiva" value={Caclientes.venporcreteiva} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={7} style={{ marginTop: '4%' }}>
                                            <Autocomplete
                                                onChange={(e, value) => { handleChangecuentas(e, value, 'cuentareteiva') }}

                                                disablePortal
                                                options={Plandecuentass}
                                                getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                value={Plandecuentass.filter(p => p.codcuenta == Caclientes.cuentareteiva)[0]}
                                                renderInput={(params) => <TextField {...params} size="small" style={{ width: '80%', borderRadius: '4px' }} label="Cuenta" />}
                                                maxHeight={200}
                                            />
                                        </Col>
                                    </Row>

                                </Col>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                            <p>Rete Ica</p>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Base Minima</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="venbaseica" value={Caclientes.venbaseica} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>
                                                <Label>Porcentaje</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="venporica" value={Caclientes.venporica} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={7} style={{ marginTop: '4%' }}>
                                            <Autocomplete
                                                onChange={(e, value) => { handleChangecuentas(e, value, 'cuentaica') }}

                                                disablePortal
                                                options={Plandecuentass}
                                                getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                value={Plandecuentass.filter(p => p.codcuenta == Caclientes.cuentaica)[0]}
                                                renderInput={(params) => <TextField {...params} size="small" style={{ width: '80%', borderRadius: '4px' }} label="Cuenta" />}
                                                maxHeight={200}
                                            />
                                        </Col>
                                    </Row>

                                </Col>

                                <Col sm={6}>
                                    <Row>
                                        <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                            <p>Rete Renta DB</p>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Base Minima</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="venbasereteredb" value={Caclientes.venbasereteredb} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>
                                                <Label>Porcentaje</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="venporreteredb" value={Caclientes.venporreteredb} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={7} style={{ marginTop: '4%' }}>
                                            <Autocomplete
                                                onChange={(e, value) => { handleChangecuentas(e, value, 'cuentareteredb') }}

                                                disablePortal
                                                options={Plandecuentass}
                                                getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                value={Plandecuentass.filter(p => p.codcuenta == Caclientes.cuentareteredb)[0]}
                                                renderInput={(params) => <TextField {...params} size="small" style={{ width: '80%', borderRadius: '4px' }} label="Cuenta" />}
                                                maxHeight={200}
                                            />
                                        </Col>
                                    </Row>

                                </Col>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                            <p>Rete Renta CR</p>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Base Minima</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="venbasereterecr" value={Caclientes.venbasereterecr} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>
                                                <Label>Porcentaje</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="venporreterecr" value={Caclientes.venporreterecr} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={7} style={{ marginTop: '4%' }}>
                                            <Autocomplete
                                                onChange={(e, value) => { handleChangecuentas(e, value, 'cuentareterecr') }}

                                                disablePortal
                                                options={Plandecuentass}
                                                getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                value={Plandecuentass.filter(p => p.codcuenta == Caclientes.cuentareterecr)[0]}
                                                renderInput={(params) => <TextField {...params} size="small" style={{ width: '80%', borderRadius: '4px' }} label="Cuenta" />}
                                                maxHeight={200}
                                            />
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                        </Tab>}
                        {(Caclientes.tercero == "proveedor") && <Tab eventKey="Impuestos Compras" title="Impuestos Compras">
                            <Row>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                            <p>Iva</p>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Base Minima</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="combaseiva" value={Caclientes.combaseiva} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>
                                                <Label>Porcentaje</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="comporciva" value={Caclientes.comporciva} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={7} style={{ marginTop: '4%' }}>
                                            <Autocomplete
                                                onChange={(e, value) => { handleChangecuentas(e, value, 'cuentacomiva') }}

                                                disablePortal
                                                options={Plandecuentass}
                                                getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                value={Plandecuentass.filter(p => p.codcuenta == Caclientes.cuentacomiva)[0]}
                                                renderInput={(params) => <TextField {...params} size="small" style={{ width: '80%', borderRadius: '4px' }} label="Cuenta" />}
                                                maxHeight={200}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                            <p>Rete Fuente</p>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Base Minima</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="combasefte" value={Caclientes.combasefte} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>
                                                <Label>Porcentaje</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="comporcfte" value={Caclientes.comporcfte} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={7} style={{ marginTop: '4%' }}>
                                            <Autocomplete
                                                onChange={(e, value) => { handleChangecuentas(e, value, 'cuentacomfte') }}

                                                disablePortal
                                                options={Plandecuentass}
                                                getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                value={Plandecuentass.filter(p => p.codcuenta == Caclientes.cuentacomfte)[0]}
                                                renderInput={(params) => <TextField {...params} size="small" style={{ width: '80%', borderRadius: '4px' }} label="Cuenta" />}
                                                maxHeight={200}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                            <p>Rete Iva</p>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Base Minima</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="combasereteiva" value={Caclientes.combasereteiva} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>
                                                <Label>Porcentaje</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="comporcreteiva" value={Caclientes.comporcreteiva} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={7} style={{ marginTop: '4%' }}>
                                            <Autocomplete
                                                onChange={(e, value) => { handleChangecuentas(e, value, 'cuentacomreteiva') }}

                                                disablePortal
                                                options={Plandecuentass}
                                                getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                value={Plandecuentass.filter(p => p.codcuenta == Caclientes.cuentacomreteiva)[0]}
                                                renderInput={(params) => <TextField {...params} size="small" style={{ width: '80%', borderRadius: '4px' }} label="Cuenta" />}
                                                maxHeight={200}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                            <p>Rete Ica</p>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Base Minima</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="combaseica" value={Caclientes.combaseica} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>
                                                <Label>Porcentaje</Label>
                                                <Input type="number" bsSize="sm" onChange={handleChange} name="comporica" value={Caclientes.comporica} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={7} style={{ marginTop: '4%' }}>
                                            <Autocomplete
                                                onChange={(e, value) => { handleChangecuentas(e, value, 'cuentacomica') }}

                                                disablePortal
                                                options={Plandecuentass}
                                                getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                value={Plandecuentass.filter(p => p.codcuenta == Caclientes.cuentacomica)[0]}
                                                renderInput={(params) => <TextField {...params} size="small" style={{ width: '80%', borderRadius: '4px' }} label="Cuenta" />}
                                                maxHeight={200}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Tab>}

                        {Caclientes.tercero == "cliente" && <Tab eventKey="Direcciones" title="Direcciones">
                            <Row>
                                <Col sm={12}>

                                    <Table striped size="sm" className="table-responsive" style={{ height: '300px' }}>
                                        <thead>
                                            <tr>
                                                <th>Direccion</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (Direcciones.length < 1) ? (
                                                    <tr>
                                                        <td colSpan="5">{<a href="#" onClick={(e) => { agregarvendedorcomisionesrecaudo(e) }} className={"badge badge-info p-2"}>{"Agregar Direcciones"}</a>}</td>
                                                    </tr>
                                                ) :
                                                    (
                                                        Direcciones.map((item, index) => (
                                                            <tr key={(index + 1)}>

                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='text' onChange={(e) => {
                                                                            var _Vendedor = Direcciones.map((dato) => { return dato })
                                                                            _Vendedor = _Vendedor.map((dato, index2) => {
                                                                                if (index == index2) {
                                                                                    var dat = {
                                                                                        ...dato,
                                                                                        ['direccion']: e.target.value
                                                                                    }
                                                                                    return dat
                                                                                } else {
                                                                                    return dato
                                                                                }
                                                                            })
                                                                            setDirecciones(_Vendedor)
                                                                            setRoles([])

                                                                        }} ultimo={false} value={Direcciones[index].direccion} onClick={() => { setfila(index) }} tabla={'vendedorcomisionesrecaudo'} name="direccion" />
                                                                    </FormGroup>
                                                                </td>

                                                                <tr>
                                                                    <td colSpan="1">{<a href="#" onClick={(e) => { agregarvendedorcomisionesrecaudo() }} className={"badge badge-info p-2"}>{"Agregar Direcciones"}</a>}</td>
                                                                </tr>
                                                            </tr>
                                                        ))
                                                    )


                                            }
                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>

                        </Tab>}

                    </ Tabs >
                    <Buscar tituloencabezado={"Buscar Municipio"} filtrar={(coincidencia) => { filtrarcodigomuns(coincidencia) }} busqueda={busquedacodigomuns} encabezado={["Codigo", "Nombre"]} codigo={"codigomun"} nombre={"nombremun"} dato={""} dato2={""} verModal={verModalcodigomun} cerrarModal={() => { cerrarModalSeleccioncodigomuns() }} seleccionar={(seleccionado) => {
                        seleccionarcodigomun(seleccionado)
                    }} />
                    <Buscar tituloencabezado={"Buscar Vendedor"} filtrar={(coincidencia) => { filtrarvendedors(coincidencia) }} busqueda={busquedavendedors} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalvendedor} cerrarModal={() => { cerrarModalSeleccionvendedors() }} seleccionar={(seleccionado) => {
                        seleccionarvendedor(seleccionado.codigo)
                    }} />
                    <Buscar tituloencabezado={"Buscar Centro de Costo"} filtrar={(coincidencia) => { filtrarccostos(coincidencia) }} busqueda={busquedaccostos} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalccosto} cerrarModal={() => { cerrarModalSeleccionccostos() }} seleccionar={(seleccionado) => {
                        seleccionarccosto(seleccionado.codigo)
                    }} />
                    <Buscar tituloencabezado={"Buscar Lista de Precio"} filtrar={(coincidencia) => { filtrarprecsugs(coincidencia) }} busqueda={busquedaprecsugs} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalprecsug} cerrarModal={() => { cerrarModalSeleccionprecsugs() }} seleccionar={(seleccionado) => {
                        seleccionarprecsug(seleccionado.codigo)
                    }} />
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalCaclientes}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Caclientes;

import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from '../../../context/UserProvider';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'

import Filtro from '../../../componentes/filtro';
const tabespacio = '\u00A0';

const modeloInicio = [{
    referencia: "",
    descripcion: "",
    ivaporc: "",
    costopromedio: "",
    costoultimo: ""

}]

const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));

    const [pendiente, setPendiente] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));
    const [fm2, setfm2] = useState(new FormatMoney({ decimals: 0 }));
    const [tipodocumentoinicioseleccionado, settipodocumentoinicioseleccionado] = useState("-");
    const [terceroinicioseleccionado, setterceroinicioseleccionado] = useState("-");
    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [tercerofinseleccionado, settercerofinseleccionado] = useState("-");
    const [filtros, setfiltros] = useState(false);
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));

    useEffect(() => {
        console.log(new Date())
        console.log(new Date('20230-01-01'))
        console.log((new Date() - new Date('20230-01-01')) / 86400000)
    }, [])
    const buscar = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        const api = fetch(props.servidor + '/CtabonoApi/encabezados/' + fechaInicio + '/' + fechaFin + '/' + tipodocumentoinicioseleccionado + '/' + tipodocumentofinseleccionado + '/' + terceroinicioseleccionado + "/" + tercerofinseleccionado + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setVentas(data);
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    const CustomTipo = ({ row }) => (
        <div>
            { }
            <div>
                <div

                >
                    { }
                    {row.nombrecli}
                </div>
            </div>
        </div>
    );
    const columns = [
        {
            name: 'NombreVen',
            sortable: true,
            grow: 4,
            selector: row => row.nombreven2,
        },
        {
            name: 'Tercero',
            sortable: true,
            grow: 4,
            cell: row => <CustomTipo row={row} />,
        },
        {
            name: 'Concepto',
            selector: row => row.tipodoc,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Consecutivo',
            selector: row => row.doc,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Concepto Factura',
            selector: row => row.tipodoc2,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Consecutivo Factura',
            selector: row => row.doc2,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Fecha',
            selector: row => row.fecha,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Valor 1',
            selector: row => fm2.from(row.bruto),
            sortable: true,
            grow: 4,
        },
        {
            name: 'Otro Ing',
            selector: row => fm2.from(row.iva),
            sortable: true,
            grow: 4,
        },
        {
            name: 'Total',
            selector: row => fm2.from((row.bruto+row.iva)),
            sortable: true,
            grow: 4,
        },
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(ventas);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Catalogo Productos.xlsx")
    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + (element.bruto+element.iva)


        }
        return tot
    }
    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                            Recaudos
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-end">

                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                                            selected={fechaInicio}
                                            value={fechaInicio}
                                            onChange={(e) => setFechaInicio(e.target.value)}></Input>

                                    </FormGroup>
                                </Col>

                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Fecha Fin:</Label>
                                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                                            selected={fechaFin}
                                            value={fechaFin}
                                            onChange={(e) => setFechaFin(e.target.value)}></Input>


                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                                        <Button style={{ backgroundColor: 'darkmagenta' }} size="sm" block onClick={() => {
                                            setfiltros(true)
                                        }}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Filtros
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="primary" size="sm" block onClick={buscar}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                                        </Button>
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={exportarExcel}>
                                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                       Total
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotal())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>

                                <Col sm="12">
                                    <DataTable
                                        progressPending={pendiente}
                                        columns={columns}
                                        data={ventas}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                    />


                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
                <Filtro
                    settipodocumentoinicioseleccionado={(e) => { settipodocumentoinicioseleccionado(e) }}
                    settipodocumentofinseleccionado={(e) => { settipodocumentofinseleccionado(e) }}
                    setterceroinicioseleccionado={(e) => { setterceroinicioseleccionado(e) }}
                    settercerofinseleccionado={(e) => { settercerofinseleccionado(e) }}


                    tipodocumentoinicioseleccionado={tipodocumentoinicioseleccionado}
                    tipodocumentofinseleccionado={tipodocumentofinseleccionado}

                    terceroinicioseleccionado={terceroinicioseleccionado}
                    tercerofinseleccionado={tercerofinseleccionado}

                    servidor={props.servidor} verModal={filtros} cerrarModal={() => { setfiltros(false) }} />
            </Row>


        </>
    )
}

export default ReporteVenta;
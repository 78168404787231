import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, CardHeader, Button, FormGroup, Label, Input, Row, Col, Table } from "reactstrap";
import Swal from 'sweetalert2';
import { UserContext } from '../context/UserProvider';
import { json } from "react-router-dom";
import * as XLSX from "xlsx"
import { Try } from "@mui/icons-material";

const FacturaBloque = (props) => {
    const { user } = useContext(UserContext);
    const [usuario, setUsuario] = useState({});
    const [fileD, setFileD] = useState([]);
    const [fechahoy, setFechahoy] = useState(new Date().toISOString().split('T')[0]);
    const [anioSeleccionado, setAnioSeleccionado] = useState('');
    const [centrosCostos, setCentrosCostos] = useState([]);
    const [productos, setProductos] = useState([]);
    const [conceptos, setConceptos] = useState([]);
    const [centroSeleccionado, setCentroSeleccionado] = useState('');
    const [reportacuantiasmenores, setreportacuantiasmenores] = useState('');
    const [tope, settope] = useState(0);
    const [productoSeleccionado, setProductoSeleccionado] = useState('');
    const [formatosbuscados, setformatosbuscados] = useState([]);
    const [conceptoSeleccionado, setConceptoSeleccionado] = useState('');
    const [fila, setfila] = useState(0);

    useEffect(() => {
        setUsuario(JSON.parse(user));
        obtenerCentrosCostos('');
        obtenerProductos('');
        obtenerConceptos('');
    }, []);
    const opcionesCentro1001 = [
        "Pago o abono en cuenta deducible",
        "Pago o abono en cuenta NO deducible",
        "IVA mayor valor del costo o gasto, deducible",
        "IVA mayor valor del costo o gasto no deducible",
        "Retención en la fuente practicada Renta",
        "Retención en la fuente asumida Renta",
        "Retención en la fuente practicada IVA a responsables del IVA",
        "Retención en la fuente practicada IVA a no residentes o no domiciliados",
    ];
    const opcionesCentro1003 = [
        "Valor acumulado del pago o abono sujeto a Retención en la fuente",
        "Retención que le practicaron"
    ];
    const obtenerCentrosCostos = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(`${props.servidor}/Ctcentrocostoapi/${coincidencia}?llave=${dt.usuario.llaveempresa}`);
            if (response.ok) {
                let data = await response.json();
                setCentrosCostos(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const obtenerProductos = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(`${props.servidor}/Inarticulosapi/${coincidencia}?llave=${dt.usuario.llaveempresa}`);
            if (response.ok) {
                let data = await response.json();
                setProductos(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const obtenerConceptos = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(`${props.servidor}/Fatipodocapi/${coincidencia}?llave=${dt.usuario.llaveempresa}`);
            if (response.ok) {
                let data = await response.json();
                setConceptos(data.filter(p => p.tipodoc == 'factura'));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const guardarFactura = async () => {
        var envioexoforma = JSON.parse(JSON.stringify(formatosbuscados))
        for (let i = 0; i < envioexoforma.length; i++) {
            envioexoforma[i].formato = centroSeleccionado
            envioexoforma[i].concepto = productoSeleccionado
            envioexoforma[i].reportacuantiasmenores = reportacuantiasmenores
            envioexoforma[i].topecuantiasmenores = tope
        }

        try {
            let dt = JSON.parse(user);
            const url = props.servidor + '/ExogenaApi/guardar/?llave=' + dt.usuario.llaveempresa;

            const requestData = {
                // Coloca aquí los datos que deseas enviar en el cuerpo de la solicitud
            };

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(envioexoforma),
            };

            const response = await fetch(url, requestOptions);

            if (response.ok) {
                const dataJson = await response.json();
                setformatosbuscados(dataJson)
            } else {
                const error = await response.text();
                Swal.fire(
                    'Opps!',
                    'No pudieron cargar los movimientos: ' + error,
                    'error'
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Card>
            <CardHeader style={{ backgroundColor: '#188BAD', color: 'white' }}>
                Factura en Bloque
            </CardHeader>
            <CardBody>
                <Row>

                    <Col sm={3}>
                        <FormGroup>
                            <Label>Formato</Label>
                            <Input type="select" value={centroSeleccionado} onChange={(e) => {
                                setCentroSeleccionado(e.target.value)
                                setformatosbuscados([])
                                setProductoSeleccionado("")
                                settope(0)
                                setreportacuantiasmenores("no")
                            }}>
                                <option value="">Seleccione...</option>
                                <option key={"1001"} value={"1001"}>{"1001"}</option>
                                <option key={"1003"} value={"1003"}>{"1003"}</option>
                                <option key={"1004"} value={"1004"}>{"1004"}</option>
                                <option key={"1005"} value={"1005"}>{"1005"}</option>
                                <option key={"1006"} value={"1006"}>{"1006"}</option>
                                <option key={"1007"} value={"1007"}>{"1007"}</option>
                                <option key={"1008"} value={"1008"}>{"1008"}</option>
                                <option key={"1009"} value={"1009"}>{"1009"}</option>
                                {/* <option key={"1010"} value={"1010"}>{"1010"}</option>stand by */}
                                {/* <option key={"1011"} value={"1011"}>{"1011"}</option>stand by */}
                                {/* <option key={"1012"} value={"1012"}>{"1012"}</option>stand by */}
                                <option key={"2276"} value={"2276"}>{"2276"}</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <Label>Concepto</Label>
                            <Input type="select" value={productoSeleccionado} onChange={async (e) => {
                                try {
                                    setProductoSeleccionado(e.target.value)


                                    let dt = JSON.parse(user)

                                    let response = await fetch(props.servidor + '/ExogenaApi/porformato/' + centroSeleccionado + '/' + e.target.value + '?llave=' + dt.usuario.llaveempresa + '');

                                    if (response.ok) {
                                        let data = await response.json()
                                        setformatosbuscados(data)
                                        try {
                                            settope(data[0].topecuantiasmenores)
                                            setreportacuantiasmenores(data[0].reportacuantiasmenores ? data[0].reportacuantiasmenores : "")
                                        } catch (error) {

                                        }
                                        // setPendiente(false)
                                    }
                                } catch (error) {
                                    console.log(error)
                                }
                            }}>
                                <option value="">Seleccione...</option>
                                {centroSeleccionado === "1001" && (
                                    <>
                                        <option value="5002">5002 - Honorarios</option>
                                        <option value="5003">5003 - Comisiones</option>
                                        <option value="5004">5004 - Servicios</option>
                                        <option value="5005">5005 - Arrendamientos</option>
                                        <option value="5006">5006 - Intereses y rendimientos financieros causados</option>
                                        <option value="5007">5007 - Compra de activos movibles (E.T.Art 60)</option>
                                        <option value="5008">5008 - Compra de Activos Fijos (E.T. Art 60)</option>
                                        <option value="5010">5010 - Pagos o Abonos en cuenta por concepto de aportes parafiscales al SENA, a las Cajas de Compensación Familiar y al Instituto Colombiano de Bienestar Familia</option>
                                        <option value="5011">5011 - Pagos o abonos en cuenta efectuado a las empresas promotoras de salud EPS y los aportes al Sistema de Riesgos Laborales, incluidos los aportes del trabajador</option>
                                        <option value="5012">5012 - Pagos o abonos en cuenta por concepto de aportes obligatorios para pensiones efectuados a los Fondos de Pensiones, incluidos los aportes del trabajador</option>
                                        <option value="5013">5013 - Donaciones en dinero efectuadas a las entidades señaladas en los artículos 125, 125-4,126-2 y 158-1 del Estatuto Tributario y la establecida en el artículo 16 de la Ley 814 de 2003, y demás que determine la ley</option>
                                        <option value="5014">5014 - Donaciones en activos diferentes a dinero efectuadas a las entidades señaladas en los artículos 125, 125-4,126-2 y 158-1 del Estatuto Tributario y la establecida en el artículo 16 de la Ley 814 de 2003, y demás que determine la ley</option>
                                        <option value="5015">5015 - El valor de los impuestos solicitados como deducción</option>
                                        <option value="5016">5016 - Los demás costos y deducciones</option>
                                        <option value="5018">5018 - Importe de las primas de reaseguros pagados o abonados en cuenta</option>
                                        <option value="5019">5019 - El monto de las amortizaciones realizadas</option>
                                        <option value="5020">5020 - Compra de activos fijos reales productivos sobre los cuales solicitó deducción, art. 158-3 E.T. El valor acumulado pagado o abonado en cuenta</option>
                                        <option value="5023">5023 - Valor acumulado de los pagos o abonos en cuenta al exterior por asistencia técnica</option>
                                        <option value="5024">5024 - Valor acumulado de los pagos o abonos en cuenta al exterior por marcas</option>
                                        <option value="5025">5025 - Valor acumulado de los pagos o abonos en cuenta al exterior por patentes</option>
                                        <option value="5026">5026 - Valor acumulado de los pagos o abonos en cuenta al exterior por regalías</option>
                                        <option value="5027">5027 - Valor acumulado de los pagos o abonos en cuenta al exterior por servicios técnicos</option>
                                        <option value="5028">5028 - El valor acumulado de la devolución de pagos o abonos en cuenta y retenciones correspondientes a operaciones de años anteriores.</option>
                                        <option value="5029">5029 - Gastos pagados por anticipado por Compras</option>
                                        <option value="5030">5030 - Gastos pagados por anticipado por Honorarios</option>
                                        <option value="5031">5031 - Gastos pagados por anticipado por Comisiones</option>
                                        <option value="5032">5032 - Gastos pagados por anticipado por Servicios</option>
                                        <option value="5033">5033 - Gastos pagados por anticipado por Arrendamientos</option>
                                        <option value="5034">5034 - Gastos pagados por anticipado por intereses y rendimientos financieros</option>
                                        <option value="5035">5035 - Gastos pagados por anticipado por otros conceptos</option>
                                        <option value="5044">5044 - El pago por loterías, rifas, apuestas y similares</option>
                                        <option value="5045">5045 - Retención sobre ingresos de tarjetas débito y crédito</option>
                                        <option value="5046">5046 - Enajenación de activos fijos de personas naturales ante oficinas de tránsito y otras entidades autorizadas</option>
                                        <option value="5047">5047 - Importe de los siniestros por lucro cesante pagados o abonados en cuenta</option>
                                        <option value="5048">5048 - Importe de los siniestros por daño emergente pagados o abonados en cuenta</option>
                                        <option value="5053">5053 - Retenciones prácticas a título de timbre</option>
                                        <option value="5054">5054 - La devolución de retenciones a título de impuesto de timbre, correspondientes a operaciones de años anteriores</option>
                                        <option value="5055">5055 - Viáticos</option>
                                        <option value="5056">5056 - Gastos de representación</option>
                                        <option value="5058">5058 - Valor de los Aportes, Tasas y Contribuciones solicitado como deducción</option>
                                        <option value="5059">5059 - El pago o abono en cuenta realizado a cada uno de los cooperados, del valor del Fondo para revalorización de aportes</option>
                                        <option value="5060">5060 - Redención de inversiones en lo que corresponde al reembolso del capital por títulos de capitalización</option>
                                        <option value="5061">5061 - Utilidades Pagadas o abonadas en cuenta, cuando el beneficiario es diferente al fideicomitente</option>
                                        <option value="5063">5063 - Intereses y rendimientos financieros efectivamente pagados</option>
                                        <option value="5064">5064 - Devoluciones de saldos de aportes pensionales pagados</option>
                                        <option value="5065">5065 - Excedentes pensionales de libre disponibilidad componente de capital pagados</option>
                                        <option value="5066">5066 - El valor del impuesto nacional al consumo solicitado como deducción.Art.512-1 E.T</option>
                                        <option value="5067">5067 - El valor acumulado de los pagos o abonos en cuenta al exterior por consultoría</option>
                                        <option value="5068">5068 - Participaciones o dividendos pagados o abonados en cuenta en calidad de exigibles correspondientes a 2016 y anteriores, parágrafo 2 artículo 49 E.T.</option>
                                        <option value="5069">5069 - Participaciones o dividendos pagados o abonados en cuenta en calidad de exigibles correspondientes a 2016 y anteriores, numeral 3 del artículo 49 E.T.</option>
                                        <option value="5070">5070 - Participaciones o dividendos pagados o abonados en cuenta en calidad de exigibles correspondientes a 2017 y siguientes, parágrafo 2 artículo 49 E.T.</option>
                                        <option value="5071">5071 - Participaciones o dividendos pagados o abonados en cuenta en calidad de exigibles correspondientes a 2017 y siguientes, numeral 3 del artículo 49 E.T.</option>
                                    </>
                                )}

                                {centroSeleccionado === "1003" && (
                                    <>
                                        <option value="1301">1301 - Retenciones por salarios, prestaciones y demás pagos laborales</option>
                                        <option value="1302">1302 - Retenciones por ventas</option>
                                        <option value="1303">1303 - Retenciones por Servicios</option>
                                        <option value="1304">1304 - Retenciones por Honorarios</option>
                                        <option value="1305">1305 - Retenciones por Comisiones</option>
                                        <option value="1306">1306 - Retenciones por Intereses y Rendimientos Financieros</option>
                                        <option value="1307">1307 - Retenciones por Arrendamientos</option>
                                        <option value="1308">1308 - Retención por Otros conceptos</option>
                                        <option value="1309">1309 - Retención en la fuente en el Impuesto a las ventas.</option>
                                        <option value="1310">1310 - Retención por dividendos y participaciones, sin incluir el valor reportado en el concepto 1320</option>
                                        <option value="1311">1311 - Retención por enajenación de activos fijos de personas naturales ante oficinas de tránsito y otras entidades autorizadas</option>
                                        <option value="1312">1312 - Retención por ingresos de tarjetas débito y crédito</option>
                                        <option value="1313">1313 - Retención por loterías, rifas, apuestas y similares</option>
                                        <option value="1314">1314 - Retención por Impuesto de Timbre</option>
                                        <option value="1320">1320 - Retención por dividendos y participaciones recibidas por sociedades nacionales art. 242-1 del E.T.</option>
                                    </>
                                )}
                                {centroSeleccionado === "1004" && (
                                    <>
                                        <option value="8303">8303 - Descuento tributario por impuestos pagados en el exterior solicitado como descuento por los contribuyentes nacionales que perciban rentas de fuente extranjera</option>
                                        <option value="8305">8305 - Descuento tributario empresas de servicios públicos domiciliarios que presten servicios de acueducto y alcantarillado</option>
                                        <option value="8316">8316 - Descuento tributario por donaciones dirigida a programas de becas o créditos condonables</option>
                                        <option value="8317">8317 - Descuento tributario por inversiones en investigación, desarrollo tecnológico e innovación</option>
                                        <option value="8318">8318 - Descuento por donaciones efectuadas a entidades sin ánimo de lucro pertenecientes al régimen tributario especial</option>
                                        <option value="8319">8319 - Descuento tributario por donaciones efectuadas a entidades sin ánimo de lucro no contribuyentes de que tratan los artículos 22 y 23 del estatuto tributario</option>
                                        <option value="8320">8320 - Descuento tributario para inversiones realizadas en control, conservación y mejoramiento del medio ambiente</option>
                                        <option value="8321">8321 - Descuento tributario por donaciones en la red nacional de bibliotecas públicas y biblioteca nacional</option>
                                        <option value="8322">8322 - Descuento tributario por donaciones a favor del fondo para reparación de víctimas</option>
                                        <option value="8323">8323 - Descuento tributario por impuestos pagados en el exterior por la Entidad controlada del Exterior (ECE)</option>
                                        <option value="8324">8324 - Descuento tributario por donación a la Corporación General Gustavo Matamoros D’ Costa y demás fundaciones dedicadas a la defensa, protección de derechos humanos</option>
                                        <option value="8325">8325 - Descuento tributario por donación a organismos de deporte aficionado</option>
                                        <option value="8326">8326 - Descuento tributario por donación a organismos deportivos y recreativos o culturales personas jurídicas sin ánimo de lucro</option>
                                        <option value="8327">8327 - Descuento tributario por donaciones efectuadas para el apadrinamiento de parques naturales y conservación de bosques naturales</option>
                                        <option value="8328">8328 - Descuento tributario por aportes al sistema general de pensiones a cargo del empleador que sea contribuyente del impuesto unificado bajo el régimen simple de tributación</option>
                                        <option value="8329">8329 - Descuento tributario por ventas de bienes o servicios realizados a través de los sistemas de tarjetas de crédito y/o débito y otros mecanismos electrónicos de pagos</option>
                                        <option value="8330">8330 - Descuento tributario por impuesto de industria, comercio, avisos y tableros</option>
                                        <option value="8331">8331 - Descuento tributario por impuesto sobre las ventas en la importación, formación, construcción, o adquisición de activos fijos reales productivos</option>
                                        <option value="8332">8332 - Descuento tributario por convenios con Coldeportes para asignar becas de estudio y manutención a deportistas talento o reserva deportiva</option>
                                        <option value="8333">8333 - Descuento para inversiones realizadas en control, conservación y mejoramiento del medio ambiente en actividades turísticas</option>
                                        <option value="8334">8334 - Descuento por donaciones realizadas a la agencia de emprendimiento e innovación del Gobierno Nacional iNNpulsa</option>
                                        <option value="8336">8336 - Descuento por donaciones recibidas por el Fondo Nacional de Financiamiento para la Ciencia, la Tecnología y la Innovación, Fondo Francisco José de Caldas</option>
                                        <option value="8337">8337 - Descuento por remuneración correspondiente a la vinculación de personal con título de doctorado en las empresas contribuyentes de renta</option>
                                        <option value="8338">8338 - Descuento por donaciones recibidas por intermedio del Icetex, dirigidas a programas de becas que financien la formación y educación de quienes ingresen a la Fuerza Pública</option>
                                    </>
                                )}
                                {centroSeleccionado === "1007" && (
                                    <>
                                        <option value="4001">4001 - Ingresos Brutos de Actividades Ordinarias</option>
                                        <option value="4002">4002 - Otros Ingresos Brutos</option>
                                        <option value="4003">4003 - Ingresos por intereses y rendimientos financieros</option>
                                        <option value="4004">4004 - Ingresos por intereses Correspondientes a Creditos Hipotecarios</option>
                                        <option value="4005">4005 - Ingresos a través de Consorcios o Uniones Temporales</option>
                                        <option value="4006">4006 - Ingresos a través de Mandato o Administración Delegada</option>
                                        <option value="4007">4007 - Ingresos a través de Exploración y Explotación de Hidrocarburos, Gases y Minerales</option>
                                        <option value="4008">4008 - Ingresos a través de Fiducia</option>
                                        <option value="4009">4009 - Ingresos a través de Terceros (Beneficiario)</option>
                                        <option value="4011">4011 - Ingresos a través de Joint Venture</option>
                                        <option value="4012">4012 - Ingresos a través de Cuentas en Participación</option>
                                        <option value="4013">4013 - Ingresos a través de Convenios de Cooperación con Entidades Públicas</option>
                                        <option value="4014">4014 - Ingresos por ventas con puntos premio redimidos en el período, procedentes de programas de fidelización</option>
                                        <option value="4015">4015 - Ingresos por puntos premio vencidos y no reclamados en el período, procedentes de programas de fidelización</option>
                                        <option value="4016">4016 - Ingresos por ventas con puntos premio redimidos en el período, incluidos en el concepto 4015 que corresponden a años anteriores</option>
                                        <option value="4017">4017 - Ingresos por recuperaciones de costos y deducciones que constituyen renta líquida</option>
                                        <option value="4018">4018 - Ingresos por diferencia en cambio</option>
                                    </>
                                )}
                                {centroSeleccionado === "1008" && (
                                    <>
                                        <option value="1315">1315 - Cuentas por Cobrar-Clientes</option>
                                        <option value="1316">1316 - Cuentas por Cobrar Compañías accionistas, socios y compañías vinculadas</option>
                                        <option value="1317">1317 - Otras Cuentas por Cobrar</option>
                                        <option value="1318">1318 - Saldo fiscal deterioro de cartera, identificando el NIT del deudor</option>
                                    </>
                                )}
                                {centroSeleccionado === "1005" && (
                                    <>
                                        <option value="2408">2408 - Impuestos Sobre las Ventas</option>
                                    </>
                                )}
                                {centroSeleccionado === "1006" && (
                                    <>
                                        <option value="SC">SC - Sin Concepto</option>
                                    </>
                                )}
                                {centroSeleccionado === "1009" && (
                                    <>
                                        <option value="2201">2201 - Saldo de los pasivos con proveedores</option>
                                        <option value="2202">2202 - Saldo de los pasivos con compañías vinculadas accionistas y socios</option>
                                        <option value="2203">2203 - Saldo de las obligaciones financieras</option>
                                        <option value="2204">2204 - Saldo de los pasivos por impuestos, gravámenes y tasas</option>
                                        <option value="2206">2206 - Saldo de los demás pasivos</option>
                                        <option value="2207">2207 - Saldo del Pasivo determinado por el cálculo Actuarial, con el NIT del informante</option>
                                        <option value="2208">2208 - Pasivos respaldados en documento de fecha cierta</option>
                                        <option value="2209">2209 - Pasivos exclusivos de las compañías de seguros</option>
                                        <option value="2211">2211 - Valor de los pasivos por depósitos judiciales. Se debe identificar al beneficiario</option>
                                        <option value="2212">2212 - Valor del pasivo por ingresos diferidos por puntos premio otorgados en programas de fidelización</option>
                                        <option value="2213">2213 - Valor del pasivo por ingresos diferidos contemplados en el art. 23-1 del E.T.</option>
                                        <option value="2214">2214 - Saldo de los pasivos por aportes parafiscales, salud, pensión y cesantías</option>
                                        <option value="2215">2215 - Saldo de los pasivos laborales reales consolidados en cabeza del trabajador, sin incluir cesantías</option>
                                    </>
                                )}



                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <Label>Reporta Cuantias Menores</Label>
                            <Input type="select" value={reportacuantiasmenores} onChange={(e) => setreportacuantiasmenores(e.target.value)}>
                                <option value="">Seleccione...</option>
                                <option key={"no"} value={"no"}>{"No"}</option>
                                <option key={"si"} value={"si"}>{"Si"}</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    {reportacuantiasmenores == "si" &&
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Tope</Label>
                                <Input type="text" value={tope} onChange={(e) => {
                                    settope(Number(e.target.value))
                                }} />

                            </FormGroup>
                        </Col>
                    }

                </Row>
                <Row>
                    <Table striped bordered hover responsive size="sm" className="mt-2" style={{ maxHeight: "300px" }}>
                        <thead className="table-dark text-center">
                            <tr>
                                <th>Cuenta Inicio</th>
                                <th>Cuenta Fin</th>
                                <th>Tipo de Movimiento</th>
                                <th>Naturaleza</th>
                                <th>Tipo Valor</th>

                            </tr>
                        </thead>
                        <tbody>
                            {formatosbuscados.length < 1 ? (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                var forma = [...formatosbuscados, [{
                                                    formato: centroSeleccionado,
                                                    concepto: productoSeleccionado,
                                                    cuentainicio: '',
                                                    cuentafin: '',
                                                    tipodemovimiento: '',
                                                    cambionaturaleza: '',
                                                    tipovalor: '',
                                                    otro: ''
                                                }]];
                                                setformatosbuscados(forma);
                                            }}
                                            className="badge bg-info p-2 text-white"
                                        >
                                            Agregar Tipo
                                        </a>
                                    </td>
                                </tr>
                            ) : (
                                <>
                                    {formatosbuscados.map((item, index) => (
                                        <tr key={index}>
                                            <td className="text-center">
                                                <Input
                                                    type="number"
                                                    onClick={() => { setfila(index) }}
                                                    value={item.cuentainicio}
                                                    onChange={(e) => {
                                                        var _Movimiento = formatosbuscados.map((dato, index2) => {
                                                            if (fila == index2) {
                                                                var dat = {
                                                                    ...dato,
                                                                    ['cuentainicio']: e.target.value
                                                                }
                                                                return dat
                                                            } else {
                                                                return dato
                                                            }
                                                        })
                                                        setformatosbuscados(_Movimiento)
                                                    }}

                                                    min={1900}
                                                    max={2100}
                                                    step="1"
                                                    className="form-control-sm text-center"
                                                />
                                            </td>
                                            <td className="text-center">
                                                <Input
                                                    type="number"
                                                    onClick={() => { setfila(index) }}
                                                    value={item.cuentafin}
                                                    onChange={(e) => {
                                                        var _Movimiento = formatosbuscados.map((dato, index2) => {
                                                            if (fila == index2) {
                                                                var dat = {
                                                                    ...dato,
                                                                    ['cuentafin']: e.target.value
                                                                }
                                                                return dat
                                                            } else {
                                                                return dato
                                                            }
                                                        })
                                                        setformatosbuscados(_Movimiento)
                                                    }}
                                                    min={1900}
                                                    max={2100}
                                                    step="1"
                                                    className="form-control-sm text-center"
                                                />
                                            </td>
                                            <td className="text-center">
                                                <Input type="select"
                                                    onClick={() => { setfila(index) }}

                                                    value={item.tipodemovimiento} onChange={(e) => {
                                                        var _Movimiento = formatosbuscados.map((dato, index2) => {
                                                            if (fila == index2) {
                                                                var dat = {
                                                                    ...dato,
                                                                    ['tipodemovimiento']: e.target.value
                                                                }
                                                                return dat
                                                            } else {
                                                                return dato
                                                            }
                                                        })
                                                        setformatosbuscados(_Movimiento)
                                                    }}>
                                                    <option value="">Seleccione...</option>
                                                    <option key={"movimiento"} value={"movimiento"}>{"Movimiento"}</option>
                                                    <option key={"saldo"} value={"saldo"}>{"Saldo"}</option>
                                                    <option key={"debito"} value={"debito"}>{"Debito"}</option>
                                                    <option key={"credito"} value={"credito"}>{"Credito"}</option>
                                                </Input>
                                            </td>
                                            <td className="text-center">
                                                <Input type="select"
                                                    onClick={() => { setfila(index) }}

                                                    value={item.cambionaturaleza} onChange={(e) => {
                                                        var _Movimiento = formatosbuscados.map((dato, index2) => {
                                                            if (fila == index2) {
                                                                var dat = {
                                                                    ...dato,
                                                                    ['cambionaturaleza']: e.target.value
                                                                }
                                                                return dat
                                                            } else {
                                                                return dato
                                                            }
                                                        })
                                                        setformatosbuscados(_Movimiento)
                                                    }}>
                                                    <option value="">Seleccione...</option>
                                                    <option key={"si"} value={"si"}>{"si"}</option>
                                                    <option key={"no"} value={"no"}>{"no"}</option>
                                                </Input>
                                            </td>
                                            <td className="text-center">
                                                <Input type="select"
                                                    onClick={() => { setfila(index) }}

                                                    value={item.tipovalor} onChange={(e) => {
                                                        var _Movimiento = formatosbuscados.map((dato, index2) => {
                                                            if (fila == index2) {
                                                                var dat = {
                                                                    ...dato,
                                                                    ['tipovalor']: e.target.value
                                                                }
                                                                return dat
                                                            } else {
                                                                return dato
                                                            }
                                                        })
                                                        setformatosbuscados(_Movimiento)
                                                    }} >
                                                    <option value="">Seleccione...</option>
                                                    {centroSeleccionado === "1001" &&
                                                        opcionesCentro1001.map((opcion, index) => (
                                                            <option key={index} value={opcion}>
                                                                {opcion}
                                                            </option>
                                                        ))}
                                                    {centroSeleccionado === "1003" &&
                                                        opcionesCentro1003.map((opcion, index) => (
                                                            <option key={index} value={opcion}>
                                                                {opcion}
                                                            </option>
                                                        ))
                                                    }
                                                    {centroSeleccionado === "1004" && (
                                                        <>
                                                            <option value="Valor del descuento tributario total del año">
                                                                Valor del descuento tributario total del año
                                                            </option>
                                                            <option value="Valor del descuento tributario efectivamente solicitado en el año gravable">
                                                                Valor del descuento tributario efectivamente solicitado en el año gravable
                                                            </option>
                                                        </>
                                                    )}
                                                    {centroSeleccionado === "1005" && (
                                                        <>
                                                            <option value="Impuesto Descontable">Impuesto Descontable</option>
                                                            <option value="IVA resultante por devoluciones en ventas anuladas, rescindidas o resueltas">
                                                                IVA resultante por devoluciones en ventas anuladas, rescindidas o resueltas
                                                            </option>
                                                        </>
                                                    )}
                                                    {centroSeleccionado === "1006" && (
                                                        <>
                                                            <option value="Impuesto generado">Impuesto generado</option>
                                                            <option value="IVA recuperado en devoluciones en compras anuladas, rescindidas o resueltas">
                                                                IVA recuperado en devoluciones en compras anuladas, rescindidas o resueltas
                                                            </option>
                                                            <option value="Impuesto al consumo">Impuesto al consumo</option>
                                                        </>
                                                    )}
                                                    {centroSeleccionado === "1007" && (
                                                        <>
                                                            <option value="Ingresos brutos recibidos">Ingresos brutos recibidos</option>
                                                            <option value="Devoluciones, rebajas y descuentos">Devoluciones, rebajas y descuentos</option>
                                                        </>
                                                    )}
                                                    {centroSeleccionado === "1008" && (
                                                        <>
                                                            <option value="Saldo cuentas por cobrar al 31-12">Saldo cuentas por cobrar al 31-12</option>
                                                        </>
                                                    )}

                                                    {centroSeleccionado === "1009" && (
                                                        <>
                                                            <option value="Saldo cuentas por pagar al 31-12">Saldo cuentas por pagar al 31-12</option>
                                                        </>
                                                    )}

                                                    {centroSeleccionado === "1010" && (
                                                        <>
                                                            <option value="Valor patrimonial acciones o aportes al 31-12">
                                                                Valor patrimonial acciones o aportes al 31-12
                                                            </option>
                                                            <option value="Porcentaje de participación">Porcentaje de participación</option>
                                                            <option value="Porcentaje de participación (posición decimal)">
                                                                Porcentaje de participación (posición decimal)
                                                            </option>
                                                        </>
                                                    )}
                                                    {centroSeleccionado === "1011" && (
                                                        <>
                                                            <option value="Saldos al 31-12">Saldos al 31-12</option>
                                                        </>
                                                    )}
                                                    {centroSeleccionado === "1012" && (
                                                        <>
                                                            <option value="Valor al 31-12">Valor al 31-12</option>
                                                        </>
                                                    )}
                                                    {centroSeleccionado === "2276" && (
                                                        <>
                                                            <option value="Pagos por Salarios">Pagos por Salarios</option>
                                                            <option value="Pagos por emolumentos eclesiásticos">Pagos por emolumentos eclesiásticos</option>
                                                            <option value="Pagos realizados con bonos electrónicos o de papel de servicio, cheques, tarjetas, vales, etc.">
                                                                Pagos realizados con bonos electrónicos o de papel de servicio, cheques, tarjetas, vales, etc.
                                                            </option>
                                                            <option value="Valor del exceso de los pagos por alimentación mayores a 41 UVT, art. 387-1 E.T.">
                                                                Valor del exceso de los pagos por alimentación mayores a 41 UVT, art. 387-1 E.T.
                                                            </option>
                                                            <option value="Pagos por honorarios">Pagos por honorarios</option>
                                                            <option value="Pagos por servicios">Pagos por servicios</option>
                                                            <option value="Pagos por comisiones">Pagos por comisiones</option>
                                                            <option value="Pagos por prestaciones sociales">Pagos por prestaciones sociales</option>
                                                            <option value="Pagos por viáticos">Pagos por viáticos</option>
                                                            <option value="Pagos por gastos de representación">Pagos por gastos de representación</option>
                                                            <option value="Pagos por compensaciones trabajo asociado cooperativo">
                                                                Pagos por compensaciones trabajo asociado cooperativo
                                                            </option>
                                                            <option value="Valor apoyos económicos no reembolsables o condonados, entregados por el Estado o financiados con recursos públicos, para financiar programas educativos.">
                                                                Valor apoyos económicos no reembolsables o condonados, entregados por el Estado o financiados con recursos públicos, para financiar programas educativos.
                                                            </option>
                                                            <option value="Otros pagos">Otros pagos</option>
                                                            <option value="Cesantías e intereses de cesantías efectivamente pagadas al empleado">
                                                                Cesantías e intereses de cesantías efectivamente pagadas al empleado
                                                            </option>
                                                            <option value="Cesantías consignadas al fondo de cesantías">
                                                                Cesantías consignadas al fondo de cesantías
                                                            </option>
                                                            <option value="Auxilio de cesantías reconocido a trabajadores del régimen tradicional del Código Sustantivo del Trabajo, Capítulo VII, Título VIII Parte Primera">
                                                                Auxilio de cesantías reconocido a trabajadores del régimen tradicional del Código Sustantivo del Trabajo, Capítulo VII, Título VIII Parte Primera
                                                            </option>
                                                            <option value="Pensiones de Jubilación, vejez o invalidez">Pensiones de Jubilación, vejez o invalidez</option>
                                                            <option value="Total ingresos brutos por rentas de trabajo y pensión">
                                                                Total ingresos brutos por rentas de trabajo y pensión
                                                            </option>
                                                            <option value="Aportes obligatorios por salud a cargo del trabajador">
                                                                Aportes obligatorios por salud a cargo del trabajador
                                                            </option>
                                                            <option value="Aportes obligatorios a fondos de pensiones y solidaridad pensional a cargo del trabajador">
                                                                Aportes obligatorios a fondos de pensiones y solidaridad pensional a cargo del trabajador
                                                            </option>
                                                            <option value="Aportes voluntarios al régimen de ahorro individual con solidaridad - RAIS">
                                                                Aportes voluntarios al régimen de ahorro individual con solidaridad - RAIS
                                                            </option>
                                                            <option value="Aportes voluntarios a fondos de pensiones voluntarias">
                                                                Aportes voluntarios a fondos de pensiones voluntarias
                                                            </option>
                                                            <option value="Aportes a cuentas AFC">Aportes a cuentas AFC</option>
                                                            <option value="Aportes a cuentas AVC">Aportes a cuentas AVC</option>
                                                            <option value="Valor de las retenciones en la fuente por pagos de rentas de trabajo o pensiones">
                                                                Valor de las retenciones en la fuente por pagos de rentas de trabajo o pensiones
                                                            </option>
                                                            <option value="Impuesto sobre las ventas – IVA, mayor valor del costo o gasto">
                                                                Impuesto sobre las ventas – IVA, mayor valor del costo o gasto
                                                            </option>
                                                            <option value="Retención en la fuente a título de impuesto sobre las ventas – IVA.">
                                                                Retención en la fuente a título de impuesto sobre las ventas – IVA.
                                                            </option>
                                                            <option value="Pagos por alimentación hasta 41 UVT">Pagos por alimentación hasta 41 UVT</option>
                                                            <option value="Valor ingreso laboral promedio de los últimos seis meses">
                                                                Valor ingreso laboral promedio de los últimos seis meses
                                                            </option>
                                                        </>
                                                    )}


                                                </Input>
                                            </td>


                                        </tr>
                                    ))}
                                    {/* Botón para agregar más filas */}
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            <button
                                                className="btn btn-success btn-sm"
                                                onClick={() => setformatosbuscados([...formatosbuscados, [{
                                                    formato: centroSeleccionado,
                                                    concepto: productoSeleccionado,
                                                    cuentainicio: '',
                                                    cuentafin: '',
                                                    tipodemovimiento: '',
                                                    cambionaturaleza: '',
                                                    tipovalor: '',
                                                    otro: ''
                                                }]])}
                                            >
                                                Agregar Otra Fila +
                                            </button>
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </Table>


                </Row>
                <Row>
                    <Col className="text-center">
                        <Button color="primary" onClick={guardarFactura}>Guardar Formatos/Concepto</Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Button color="primary" onClick={async () => {
                            try {


                                let dt = JSON.parse(user)

                                let response = await fetch(props.servidor + '/ExogenaApi/crearexogena/' + centroSeleccionado + '?llave=' + dt.usuario.llaveempresa + '');

                                if (response.ok) {
                                    let data = await response.json()
                                    var wb = XLSX.utils.book_new();

                                    var ws = XLSX.utils.json_to_sheet(data);

                                    XLSX.utils.book_append_sheet(wb, ws, "Reporte");
                                    XLSX.writeFile(wb, "Exogena Formato " + centroSeleccionado + ".xlsx")
                                    // setPendiente(false)
                                }
                            } catch (error) {
                                console.log(error)
                            }
                        }}>Generar Formato Exogena</Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default FacturaBloque;
